import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './contact.css'

const Contact = (props) => {
  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact - Feelers 2024</title>
        <meta property="og:title" content="Contact - Feelers 2024" />
      </Helmet>
      <div className="contact-bg-container">
        <img src="/feelers/bgcircle.png" className="contact-image" />
        <img src="/feelers/bgcircle.png" className="contact-image1" />
      </div>
      <div className="contact-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="contact-body-container">
        <span className="contact-text Body-XL">
          Reach out to us for conversations and collaborations.
        </span>
        <span className="contact-text1 Alt1-M">
          <span>team@feelers-feelers.com</span>
          <br></br>
          <span>284 River Valley Road,</span>
          <br></br>
          <span>Singapore 238325</span>
          <br></br>
        </span>
      </div>
      <div className="contact-footer-container">
        <Footer rootClassName="footer-root-class-name"></Footer>
      </div>
    </div>
  )
}

export default Contact
