import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Plot from '../components/plot'
import Footer from '../components/footer'
import './archive.css'

const Archive = (props) => {
  return (
    <div className="archive-container">
      <Helmet>
        <title>Archive - Feelers 2024</title>
        <meta property="og:title" content="Archive - Feelers 2024" />
      </Helmet>
      <div className="archive-bg-container">
        <img src="/feelers/bgcircle.png" className="archive-image" />
        <img src="/feelers/bgcircle.png" className="archive-image1" />
        <img src="/feelers/bgcircle.png" className="archive-image2" />
        <div className="archive-hori-line"></div>
        <div className="archive-vert-line"></div>
      </div>
      <div className="archive-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="archive-body-container">
        <div className="archive-graph-container">
          <Link to="/greener-screens">
            <div className="archive-greener-screens">
              <Plot text="Greener Screens"></Plot>
            </div>
          </Link>
          <Link to="/labs">
            <div className="archive-labs">
              <Plot text="Labs"></Plot>
            </div>
          </Link>
          <Link to="/caterfeelers">
            <div className="archive-caterfeelers">
              <Plot text="caterfeelers"></Plot>
            </div>
          </Link>
          <Link to="/interactive-rotary-phone">
            <div className="archive-rotary-phone">
              <Plot text="Interactive Rotary Phone"></Plot>
            </div>
          </Link>
          <Link to="/you-tube">
            <div className="archive-youtube">
              <Plot text="YouTube"></Plot>
            </div>
          </Link>
          <Link to="/streetlamp-no-107101">
            <div className="archive-streetlamp">
              <Plot text="Streetlamp No. 107101"></Plot>
            </div>
          </Link>
          <Link to="/kinetic-birds">
            <div className="archive-kinetic-birds">
              <Plot text="Kinetic Birds"></Plot>
            </div>
          </Link>
          <Link to="/talk-touch">
            <div className="archive-talktouch">
              <Plot text="TalkTouch"></Plot>
            </div>
          </Link>
          <Link to="/generative-dreaming">
            <div className="archive-generative-dreaming">
              <Plot text="Generative Dreaming"></Plot>
            </div>
          </Link>
          <Link to="/build-a-caifan">
            <div className="archive-caifan">
              <Plot text="Build-A-Caifan"></Plot>
            </div>
          </Link>
          <Link to="/good-am-to-you">
            <div className="archive-good-am">
              <Plot text="Good AM to You"></Plot>
            </div>
          </Link>
          <Link to="/another-world-is-possible">
            <div className="archive-another-world">
              <Plot text="Another World is Possible"></Plot>
            </div>
          </Link>
        </div>
        <div className="archive-tagline-container">
          <h1 className="archive-heading Alt1-L">Signal,</h1>
          <h1 className="archive-heading1 Alt2-L"> not </h1>
          <h1 className="archive-heading2 Title-XXL">noise.</h1>
        </div>
      </div>
      <div className="archive-footer-container">
        <Footer rootClassName="footer-root-class-name"></Footer>
      </div>
    </div>
  )
}

export default Archive
