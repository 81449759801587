import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import "./soai-lite.css";

const SoaiLite = (props) => {
  return (
    <div className="soai-lite-container">
      <Helmet>
        <title>Soai-Lite - Feelers 2024</title>
        <meta property="og:title" content="Soai-Lite - Feelers 2024" />
      </Helmet>
      <div className="soai-lite-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="soai-lite-body">
        <div className="soai-lite-r1">
          <span className="soai-lite-t1 SoAI-M">
            Join us in December 2023 for a fully-online version of Feelers’ new
            alt-education programme for critical thinking, doing, and undoing
            around technology.
          </span>
          <img src="/soai/sl-2-1500w.webp" className="soai-lite-i2" />
          <span className="soai-lite-t2 SoAI-M">
            Led by artists Ashley Hi, Johann Yamin, ants chua and Ang Kia Yee,
            the programme spans approaches across visual arts, new media
            studies, computing, creative writing, and performance. Participate
            from wherever you are in the world, for whichever sessions you’d
            like to attend.
          </span>
        </div>
        <div className="soai-lite-r2">
          <div className="soai-lite-r2-c1">
            <span className="SoAI-M soai-lite-t4">
              The School of Alternate Internets seeks to expand public
              understanding of technology, foster critical thinking and creative
              exploration. By offering alternate perspectives on emerging
              technologies, we aim to empower participants with the knowledge
              and confidence to navigate the evolving technological landscape.
            </span>
            <span className="soai-lite-t5 SoAI-M">
              <span className="soai-lite-text">
                With recent promises of “Web3” dominating visions of the
                Internet to come, the 2023 programme thinks through three highly
                contested areas seen as pivotal to these technological futures:
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className="SoAI-MB">Artificial Intelligence</span>
              <span className="soai-lite-text02">
                ,
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className="SoAI-MB">Blockchain</span>
              <span className="soai-lite-text04">
                , and the
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className="SoAI-MB">Metaverse</span>
              <span className="soai-lite-text06">.</span>
            </span>
          </div>
          <div className="soai-lite-r1-r2">
            <a
              href="https://drive.google.com/file/d/1qFqR4X6YWkSYIN1CeGkfoQ63RB6wi6VD/view?usp=sharing"
              target="_blank"
              rel="noreferrer noopener"
              className="soai-lite-t3 SoAI-M"
            >
              See Program Schedule
            </a>
            <span className="soai-lite-t41 SoAI-M">
              <span>Fees for each session are $10</span>
              <br className="SoAI-M"></br>
              <span>Sessions will be held online over Zoom</span>
            </span>
          </div>
        </div>
        <div className="soai-lite-r3">
          <div className="soai-lite-r3-c1">
            <span className="soai-lite-t10">Presenters</span>
            <span className="soai-lite-t12 SoAI-MB">ants chua</span>
            <span className="soai-lite-t11 SoAI-M">
              As a recovering web3 skeptic, ants is finding their way amidst the
              landscape of emerging technologies.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="soai-lite-t121 SoAI-MB">Ashley Hi</span>
            <span className="soai-lite-t111 SoAI-M">
              Ashley is a visual artist who utilises kinetic sculpture and
              installation to explore medium, material, and movement. Her works
              include speculative machine learning, kinetic installation and
              soundscapes, as well as generative tracking and algorithmic
              mark-making. Her current research interests include ethical
              software and hardware computing practices which promote
              transparency and sustainable energy consumption such as
              permacomputing and reversible computing.
            </span>
            <span className="soai-lite-t122 SoAI-MB">Ang Kia Yee</span>
            <span className="soai-lite-t112 SoAI-M">
              Kia Yee is a practice of writing, performance, and repair. She
              writes poetry, essays, fiction, and performance texts. She
              co-creates performances with people, objects, and environments.
              Her artistic allegiance is to the transdisciplinary and
              reparative. Most of their projects lope toward the
              destabilisation, contamination, mutation, and cross-pollination of
              disciplines, forms, and languages. They lead Feelers in bid of
              more sustainable and nurturing ways of working full-time for
              artists.
            </span>
            <span className="soai-lite-t123 SoAI-MB">Johann Yamin</span>
            <span className="soai-lite-t113 SoAI-M">
              <span className="soai-lite-text10 SoAI-M">
                Johann Yamin’s writing and research focus on digital cultures
                from the contexts of Singapore and its broader region of
                Southeast Asia, with an emphasis on the materiality of
                communication infrastructures and their entanglements with
                colonial histories. His projects have taken shape as essays,
                moving image installations, and text-based videogames, alongside
                curatorial work and varied forms of support. He was previously a
                2020 Rapid Response for a Better Digital Future Fellow at
                Eyebeam, New York for co-organising the online project Pulau
                Something, and a Curatorial &amp; Research Resident at the
                Singapore Art Museum in 2021. He was awarded a Rhizome
                Microgrant in 2023 to write about the lifeworlds and afterlives
                of Flash-based artworks.
              </span>
              <span></span>
            </span>
          </div>
        </div>
      </div>
      <div className="soai-lite-footer-container">
        <Footer rootClassName="footer-root-class-name28"></Footer>
      </div>
    </div>
  );
};

export default SoaiLite;
