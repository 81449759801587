import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './soai-july.css'

const SoaiJuly = (props) => {
  return (
    <div className="soai-july-container">
      <Helmet>
        <title>Soai-July - Feelers 2024</title>
        <meta property="og:title" content="Soai-July - Feelers 2024" />
      </Helmet>
      <div className="soai-july-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="soai-july-body">
        <div className="soai-july-r1">
          <div className="soai-july-r1-r1">
            <div className="soai-july-r1-r1-c1">
              <img
                src="/soai/wifi%20flower%403x-300w.webp"
                className="soai-july-i1"
              />
            </div>
            <div className="soai-july-r1-r1-c2">
              <span className="soai-july-t1 SoAI-M">
                Over two weekends in July 2023, 12 participants came together
                for the inaugural run of The School of Alternate Internets, an
                educational programme by Feelers for critical thinking, doing,
                and undoing around technology.
              </span>
              <img src="/soai/soai-logo-1500w.webp" className="soai-july-i2" />
            </div>
            <div className="soai-july-r1-r1-c3">
              <img
                src="/soai/yellow%20spark%403x-300w.webp"
                className="soai-july-i3"
              />
            </div>
          </div>
          <div className="soai-july-r1-r2">
            <span className="soai-july-t2 SoAI-MB">
              <span>Inaugural Edition</span>
              <br></br>
              <span>July 15–16 &amp; July 22–23 2023</span>
            </span>
            <a
              href="https://drive.google.com/file/d/1BrdpbBJX9aflwiUa1LSZAxuVHSYtt9pP/view?usp=sharing"
              target="_blank"
              rel="noreferrer noopener"
              className="soai-july-t3 SoAI-M"
            >
              <span>See July 2023</span>
              <br></br>
              <span>Program Schedule</span>
              <br></br>
            </a>
          </div>
        </div>
        <div className="soai-july-r2">
          <div className="soai-july-r2-c1">
            <span className="SoAI-M soai-july-t4">
              The School of Alternate Internets seeks to expand public
              understanding of technology, foster critical thinking and creative
              exploration. By offering alternate perspectives on emerging
              technologies, we aim to empower participants with the knowledge
              and confidence to navigate the evolving technological landscape.
            </span>
            <span className="soai-july-t5 SoAI-M">
              <span className="soai-july-text07">
                With recent promises of “Web3” dominating visions of the
                Internet to come, the 2023 programme thinks through three highly
                contested areas seen as pivotal to these technological futures:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="SoAI-MB">Artificial Intelligence</span>
              <span className="soai-july-text09">
                ,
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="SoAI-MB">Blockchain</span>
              <span className="soai-july-text11">
                , and the
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="SoAI-MB">Metaverse</span>
              <span className="soai-july-text13">.</span>
            </span>
          </div>
          <div className="soai-july-r2-c2">
            <img src="/soai/monitor%403x-800w.webp" className="soai-july-i31" />
          </div>
        </div>
        <div className="soai-july-r3">
          <span className="soai-july-t6">Class of 2023</span>
          <span className="soai-july-t7 SoAI-M">
            Participants in this first run program came from a wide variety of
            backgrounds, including UI/X design, animation, illustration,
            theatre, and tech journalism, bringing a diverse set of interests
            and perspectives to the room.
          </span>
          <div className="soai-july-r3-r1">
            <a
              href="https://www.instagram.com/douchebagbobo/"
              target="_blank"
              rel="noreferrer noopener"
              className="soai-july-link"
            >
              <div className="soai-july-r3-r1-c1">
                <span className="soai-july-t8 SoAI-MB">Esther Lim</span>
                <span className="soai-july-t9 SoAI-M">
                  Illustrator and animator
                </span>
              </div>
            </a>
            <a
              href="https://www.instagram.com/prickly__heat/"
              target="_blank"
              rel="noreferrer noopener"
              className="soai-july-link1"
            >
              <div className="soai-july-r3-r1-c2">
                <span className="soai-july-t81 SoAI-MB">Hanae Gomez</span>
                <span className="soai-july-t91 SoAI-M">
                  Illustrator, art director and animator
                </span>
              </div>
            </a>
            <a
              href="https://www.behance.net/foler"
              target="_blank"
              rel="noreferrer noopener"
              className="soai-july-link2"
            >
              <div className="soai-july-r3-r1-c3">
                <span className="soai-july-t82 SoAI-MB">Foler Goh</span>
                <span className="soai-july-t92 SoAI-M">
                  UI/UX designer and illustrator
                </span>
              </div>
            </a>
          </div>
          <div className="soai-july-r3-r2">
            <a
              href="https://wangyifan.io/"
              target="_blank"
              rel="noreferrer noopener"
              className="soai-july-link3"
            >
              <div className="soai-july-r3-r2-c1">
                <span className="soai-july-t83 SoAI-MB">Yifan Wang</span>
                <span className="soai-july-t93 SoAI-M">
                  Tech journalist and new media artist
                </span>
              </div>
            </a>
            <div className="soai-july-r3-r2-c3">
              <span className="soai-july-t84 SoAI-MB">Vanessa Ong</span>
              <span className="soai-july-t94 SoAI-M">
                UI/UX designer specialising in crafting digital interfaces for
                brand experiences
              </span>
            </div>
            <a
              href="https://victoriahertel.github.io"
              target="_blank"
              rel="noreferrer noopener"
              className="soai-july-link4"
            >
              <div className="soai-july-r3-r2-c4">
                <span className="soai-july-t85 SoAI-MB">Victorial Hertel</span>
                <span className="soai-july-t95 SoAI-M">
                  Artist whose techno-organic practice explores the entanglement
                  of the self and non-self
                </span>
              </div>
            </a>
          </div>
          <div className="soai-july-r3-r3">
            <a
              href="https://succumbing.net/"
              target="_blank"
              rel="noreferrer noopener"
              className="soai-july-link5"
            >
              <div className="soai-july-r3-r3-c1">
                <span className="soai-july-t86 SoAI-MB">Clara Lee</span>
                <span className="soai-july-t96 SoAI-M">
                  Writer, researcher and cultural worker
                </span>
              </div>
            </a>
            <a
              href="https://www.guakhee.com/"
              target="_blank"
              rel="noreferrer noopener"
              className="soai-july-link6"
            >
              <div className="soai-july-r3-r3-c2">
                <span className="soai-july-t87 SoAI-MB">Chong Gua Khee</span>
                <span className="soai-july-t97 SoAI-M">
                  Performer, director, dramaturg and facilitator
                </span>
              </div>
            </a>
            <a
              href="https://linktr.ee/claracxw"
              target="_blank"
              rel="noreferrer noopener"
              className="soai-july-link7"
            >
              <div className="soai-july-r3-r3-c3">
                <span className="soai-july-t88 SoAI-MB">Clara Peh</span>
                <span className="soai-july-t98 SoAI-M">
                  Independent curator and art writer
                </span>
              </div>
            </a>
          </div>
        </div>
        <div className="soai-july-r4">
          <div className="soai-july-r4-c1">
            <span className="soai-july-t10">
              Hackathon &amp; Closing Thoughts
            </span>
            <span className="soai-july-t11 SoAI-M">
              Having explored the constellation of technologies surrounding
              Web3, the fourth day of the School of Alternate Internets gave
              participants an opportunity to consolidate their learning through
              a day-long collaborative hackathon. Though this was initially an
              intimidating prospect, the group came together to write, code, and
              make gifs to create a twine experience that you can access here.
            </span>
            <a
              href="https://futurepepper.itch.io/soai-new-friend-draft-1"
              target="_blank"
              rel="noreferrer noopener"
              className="soai-july-t12 SoAI-M"
            >
              <span>New Friend</span>
              <br></br>
              <span>an interactive experience</span>
              <br></br>
            </a>
            <span className="soai-july-t111 SoAI-M">
              Driven by artistic research, discussions, and hands-on activities,
              artists Ashley Hi, Johann Yamin, and Ang Kia Yee offered
              participants a unique opportunity to engage with Artificial
              Intelligence, the Metaverse, and Blockchain through the lenses of
              visual art, new media studies, creative writing, performance, and
              computing. We’re deeply grateful for our participants’ time and
              energy and excited to continue developing the School of Alternate
              Internets in a variety of formats.
            </span>
            <span className="soai-july-t112 SoAI-M">
              With this programme and others, Feelers hopes to expand ways of
              doing, feeling, and thinking about technology beyond its usual
              languages and logics. Through play, hacking, and exploration, we
              seek to build liberatory, innovative, and nourishing relationships
              to technology—that which has arrived, and that which continues to
              emerge.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </div>
          <img src="/soai/leaf%403x-600w.webp" className="soai-july-i4" />
        </div>
        <div className="soai-july-r5">
          <div className="soai-july-r5-c1">
            <span className="soai-july-t101">Testimonials</span>
            <span className="soai-july-t113">
              <span className="soai-july-text18 SoAI-M">
                “Overall I really enjoyed my SoAI experience because of the
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="SoAI-MB">well-structured learning blocks</span>
              <span className="soai-july-text20 SoAI-M">
                , the
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="SoAI-MB">breadth of topics</span>
              <span className="soai-july-text22 SoAI-M">
                {' '}
                from which to gather new information and insights and the
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="SoAI-MB">diversity of the participants</span>
              <span className="soai-july-text24 SoAI-M">
                {' '}
                which came from different fields relating to art-tech.”
              </span>
            </span>
            <span className="soai-july-t121 SoAI-MB">Victoria Hertel</span>
            <span className="soai-july-t13">
              <span className="soai-july-text25 SoAI-M">
                “I really appreciated the more
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="SoAI-MB">expansive lenses</span>
              <span className="soai-july-text27 SoAI-M">
                {' '}
                that SoAI took with regards to the web and associated
                technologies, which is a
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="SoAI-MB">unique and refreshing</span>
              <span className="soai-july-text29 SoAI-M">
                {' '}
                departure from the more functional / professional development
                lens that I see in other 101-level workshops (:”
              </span>
            </span>
            <span className="soai-july-t122 SoAI-MB">Chong Gua Khee</span>
            <span className="soai-july-t14">
              <span className="soai-july-text30 SoAI-M">
                “I have
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="SoAI-MB">gained a better sense of Web3</span>
              <span className="soai-july-text32 SoAI-M">
                , and its contemporary discourse through SoAI. I found that the
                programme, and in particular the primers were
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="SoAI-MB">thoughtfully designed</span>
              <span className="soai-july-text34 SoAI-M">
                {' '}
                for an introductory workshop on Web3. Rather than having gained
                a firm grasp on the topics covered, I feel more able at locating
                where some of my questions and curiosities lie in these emergent
                technologies, and thus more confident in negotiating the various
                “tentacles” of Web3 in my own terms. That being said, I
                appreciated how the exercises
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="SoAI-MB">
                encouraged experimentation and curiosity
              </span>
              <span className="soai-july-text36 SoAI-M">.”</span>
              <span></span>
            </span>
            <span className="soai-july-t123 SoAI-MB">Clara Lee</span>
            <span className="soai-july-t15">
              <span className="soai-july-text38 SoAI-M">
                “I really appreciate how SoAI provided a
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="SoAI-MB">
                well-rounded view of the development of Web3
              </span>
              <span className="soai-july-text40 SoAI-M">
                {' '}
                from a historical, technical, moral and philosophical
                standpoint. I think it made it easy for an outsider like me to
                learn about Web3 and think about how my practice can evolve
                within Web3. The small exercises after each lecture also helped
                me reflect on whatever was discussed. It’s a really nice
                experience. I also liked that SoAI was a
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="SoAI-MB">safe space</span>
              <span className="soai-july-text42 SoAI-M">
                {' '}
                where my questions would not be treated as stupid questions and
                I liked that the other participants came from such diverse
                backgrounds.”
              </span>
            </span>
            <span className="soai-july-t124 SoAI-MB">Esther Lim</span>
            <span className="soai-july-t16">
              <span className="soai-july-text43 SoAI-M">
                “The Web3 workshop left me feeling excited and inspired! Feelers
                provided a
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="SoAI-MB">
                clear and in-depth understanding of how Web3 works
              </span>
              <span className="soai-july-text45 SoAI-M">
                , allowing me to spot opportunities for future innovation. They
                were able to present Web3 terms in digestible ways and have also
                provided us with ample time for reflection, making it easy for
                beginners like me to absorb the information. The safe and
                welcoming space they created throughout the session contributed
                to a
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="SoAI-MB">
                positive and enriching learning experience
              </span>
              <span className="soai-july-text47 SoAI-M">
                {' '}
                [...] It is evident that Feelers cared about delivering a
                valuable and enriching learning journey and I
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="SoAI-MB">
                highly recommend this workshop to anyone interested in exploring
                emerging technologies
              </span>
              <span className="soai-july-text49 SoAI-M">.”</span>
            </span>
            <span className="soai-july-t125 SoAI-MB">Vanessa Ong</span>
          </div>
        </div>
        <div className="soai-july-r6">
          <img
            src="/soai/caterpillar%403x-800w.webp"
            className="soai-july-i5"
          />
          <div className="soai-july-r6-c1">
            <span className="SoAI-M soai-july-t17">
              <span className="SoAI-M soai-july-text50">
                Didn’t get a chance to attend the first edition of School of
                Alternate Internets? We’re running a fully-online, modular
                version of the program.
              </span>
              <br className="SoAI-M soai-july-text51"></br>
              <br className="SoAI-M soai-july-text52"></br>
              <span className="SoAI-M soai-july-text53">
                Join us for SoAI Lite in December 2023, from wherever you are in
                the world, for whichever sessions you want to attend.
              </span>
              <span></span>
            </span>
            <a
              href="https://forms.gle/k7vTrs6go3GvjwPz8"
              target="_blank"
              rel="noreferrer noopener"
              className="soai-july-t18 SoAI-M"
            >
              <span>Sign up now!</span>
              <br></br>
            </a>
          </div>
          <img
            src="/soai/spark%20with%20cursor%403x-800w.webp"
            className="soai-july-i6"
          />
        </div>
      </div>
      <div className="soai-july-footer-container">
        <Footer rootClassName="footer-root-class-name27"></Footer>
      </div>
    </div>
  )
}

export default SoaiJuly
