import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './services.css'

const Services = (props) => {
  return (
    <div className="services-container">
      <Helmet>
        <title>Services - Feelers 2024</title>
        <meta property="og:title" content="Services - Feelers 2024" />
      </Helmet>
      <div className="services-bg-container">
        <img src="/feelers/bgcircle.png" className="services-image" />
        <img src="/feelers/bgcircle.png" className="services-image1" />
        <img src="/feelers/bgcircle.png" className="services-image2" />
      </div>
      <div className="services-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="services-body-container">
        <div className="services-top-container">
          <h1 className="services-text Title-M">
            We&apos;re available for hire
          </h1>
        </div>
        <div className="services-body-container1">
          <div className="services-row1-container">
            <div className="services-col1-container">
              <span className="services-text01 Title-L">Art &amp; Tech</span>
            </div>
            <div className="services-col234-container">
              <div className="services-row1-container1">
                <div className="services-col2-container">
                  <span className="services-text02 Body-L">
                    Brand Environments
                  </span>
                </div>
              </div>
              <div className="services-row2-container">
                <div className="services-col2-container01">
                  <span className="services-text03 Body-M">
                    We elevate brand messages and value through site-specific
                    art, including interactive installations and live
                    performance.
                  </span>
                  <span className="services-text04 Body-M">
                    Retail Spaces (Visual Merchandising)
                  </span>
                  <span className="services-text05 Body-M">Office Spaces</span>
                </div>
                <div className="services-col3-container">
                  <span className="services-text06 Body-M">
                    Creative Computing
                  </span>
                  <span className="services-text07 Body-M">
                    Interactive Installations
                  </span>
                  <span className="services-text08 Body-M">
                    {' '}
                    Live Performance
                  </span>
                </div>
                <div className="services-col4-container">
                  <span className="services-text09 Body-M">From $5,000</span>
                  <span className="services-text10 Body-M">Expedite $800</span>
                </div>
              </div>
              <div className="services-row3-container">
                <div className="services-col2-container02">
                  <span className="services-text11 Body-L">
                    Artist Projects
                  </span>
                </div>
              </div>
              <div className="services-row4-container">
                <div className="services-col2-container03">
                  <span className="services-text12 Body-M">
                    We work with artists to realise their vision, including
                    interactive installations and live performance.
                  </span>
                </div>
                <div className="services-col3-container1">
                  <div
                    data-thq="thq-dropdown"
                    className="services-physical list-item Body-M"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="services-dropdown-toggle"
                    >
                      <span className="Body-M services-text13">
                        Physical Computing
                      </span>
                      <div data-thq="thq-dropdown-arrow">
                        <svg viewBox="0 0 1024 1024" className="services-icon">
                          <path d="M426 726v-428l214 214z"></path>
                        </svg>
                      </div>
                    </div>
                    <ul
                      data-thq="thq-dropdown-list"
                      className="services-dropdown-list"
                    >
                      <li
                        data-thq="thq-dropdown"
                        className="services-dropdown list-item"
                      >
                        <div
                          data-thq="thq-dropdown-toggle"
                          className="services-dropdown-toggle01"
                        >
                          <span className="services-text14 Body-M">
                            Arduino, ESP32/8266, Teensy
                          </span>
                        </div>
                      </li>
                      <li
                        data-thq="thq-dropdown"
                        className="services-dropdown01 list-item"
                      >
                        <div
                          data-thq="thq-dropdown-toggle"
                          className="services-dropdown-toggle02"
                        >
                          <span className="services-text15 Body-S">
                            Basic (No Code)
                          </span>
                          <span className="services-text16 Body-S">
                            standard rotary motion, non-electrical systems
                          </span>
                        </div>
                      </li>
                      <li
                        data-thq="thq-dropdown"
                        className="services-dropdown02 list-item"
                      >
                        <div
                          data-thq="thq-dropdown-toggle"
                          className="services-dropdown-toggle03"
                        >
                          <span className="services-text17 Body-S">
                            Advanced Kinetic
                          </span>
                          <span className="services-text18 Body-S">
                            complex motion sequencing
                          </span>
                        </div>
                      </li>
                      <li
                        data-thq="thq-dropdown"
                        className="services-dropdown03 list-item"
                      >
                        <div
                          data-thq="thq-dropdown-toggle"
                          className="services-dropdown-toggle04"
                        >
                          <span className="services-text19 Body-S">
                            Audio
                            <span
                              dangerouslySetInnerHTML={{
                                __html: ' ',
                              }}
                            />
                          </span>
                          <span className="services-text20 Body-S">
                            analog audio mixing / sequencing
                          </span>
                        </div>
                      </li>
                      <li
                        data-thq="thq-dropdown"
                        className="services-dropdown04 list-item"
                      >
                        <div
                          data-thq="thq-dropdown-toggle"
                          className="services-dropdown-toggle05"
                        >
                          <span className="services-text21 Body-S">
                            Interaction
                            <span
                              dangerouslySetInnerHTML={{
                                __html: ' ',
                              }}
                            />
                          </span>
                          <span className="services-text22 Body-S">
                            interfacing with buttons, proximity sensors, camera
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    data-thq="thq-dropdown"
                    className="services-digital Body-M list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="services-dropdown-toggle06"
                    >
                      <span className="Body-M services-text23">
                        Digital Computing
                      </span>
                      <div data-thq="thq-dropdown-arrow">
                        <svg
                          viewBox="0 0 1024 1024"
                          className="services-icon02"
                        >
                          <path d="M426 726v-428l214 214z"></path>
                        </svg>
                      </div>
                    </div>
                    <ul
                      data-thq="thq-dropdown-list"
                      className="services-dropdown-list1"
                    >
                      <li
                        data-thq="thq-dropdown"
                        className="services-dropdown05 list-item"
                      >
                        <div
                          data-thq="thq-dropdown-toggle"
                          className="services-dropdown-toggle07"
                        >
                          <span className="services-text24 Body-M">
                            p5, React, Node, ml5, TensorFlow
                          </span>
                        </div>
                      </li>
                      <li
                        data-thq="thq-dropdown"
                        className="services-dropdown06 list-item"
                      >
                        <div
                          data-thq="thq-dropdown-toggle"
                          className="services-dropdown-toggle08"
                        >
                          <span className="services-text25 Body-S">
                            Single Pages Apps / Websites
                          </span>
                          <span className="services-text26 Body-S">
                            microsites, image generators, drawing tools
                          </span>
                        </div>
                      </li>
                      <li
                        data-thq="thq-dropdown"
                        className="services-dropdown07 list-item"
                      >
                        <div
                          data-thq="thq-dropdown-toggle"
                          className="services-dropdown-toggle09"
                        >
                          <span className="services-text27 Body-S">
                            Web Extensions
                          </span>
                          <span className="services-text28 Body-S">
                            remote wifi interactions, data visualisation, GUIs
                          </span>
                        </div>
                      </li>
                      <li
                        data-thq="thq-dropdown"
                        className="services-dropdown08 list-item"
                      >
                        <div
                          data-thq="thq-dropdown-toggle"
                          className="services-dropdown-toggle10"
                        >
                          <span className="services-text29 Body-S">
                            Machine Learning
                          </span>
                          <span className="services-text30 Body-S">
                            generative text / images, image recognition, webcam
                            interaction
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="services-col4-container1">
                  <span className="services-text31 Body-M">From $800</span>
                  <span className="services-text32 Body-M">Expedite $200</span>
                </div>
              </div>
              <div className="services-row5-container">
                <div className="services-col2-container04">
                  <span className="services-text33 Body-L">Tech Support</span>
                </div>
              </div>
              <div className="services-row6-container">
                <div className="services-col2-container05">
                  <span className="services-text34 Body-M">
                    We support the maintenance and repair of artworks involving
                    tech.
                  </span>
                </div>
                <div className="services-col3-container2">
                  <div
                    data-thq="thq-dropdown"
                    className="services-ashley Body-M list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="services-dropdown-toggle11"
                    >
                      <span className="Body-M services-text35">Ashley</span>
                      <div data-thq="thq-dropdown-arrow">
                        <svg
                          viewBox="0 0 1024 1024"
                          className="services-icon04"
                        >
                          <path d="M426 726v-428l214 214z"></path>
                        </svg>
                      </div>
                    </div>
                    <ul
                      data-thq="thq-dropdown-list"
                      className="services-dropdown-list2"
                    >
                      <li
                        data-thq="thq-dropdown"
                        className="services-dropdown09 list-item"
                      >
                        <div
                          data-thq="thq-dropdown-toggle"
                          className="services-dropdown-toggle12"
                        >
                          <span className="services-text36 Body-S">
                            Arduino / ESP / Teensy
                          </span>
                          <span className="services-text37 Body-S">
                            motion sequencing, interfacing with buttons &amp;
                            sensors, remote wifi &amp; database interaction,
                            analog audio mixing &amp; sequencing
                          </span>
                        </div>
                      </li>
                      <li
                        data-thq="thq-dropdown"
                        className="services-dropdown10 list-item"
                      >
                        <div
                          data-thq="thq-dropdown-toggle"
                          className="services-dropdown-toggle13"
                        >
                          <span className="services-text38 Body-S">
                            Web / Visuals
                          </span>
                          <span className="services-text39 Body-S">
                            HTML + CSS + JavaScript, p5, React, Node, ml5
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    data-thq="thq-dropdown"
                    className="services-sixuan Body-M list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="services-dropdown-toggle14"
                    >
                      <span className="Body-M services-text40">Si Xuan</span>
                      <div data-thq="thq-dropdown-arrow">
                        <svg
                          viewBox="0 0 1024 1024"
                          className="services-icon06"
                        >
                          <path d="M426 726v-428l214 214z"></path>
                        </svg>
                      </div>
                    </div>
                    <ul
                      data-thq="thq-dropdown-list"
                      className="services-dropdown-list3"
                    >
                      <li
                        data-thq="thq-dropdown"
                        className="services-dropdown11 list-item"
                      >
                        <div
                          data-thq="thq-dropdown-toggle"
                          className="services-dropdown-toggle15"
                        >
                          <span className="services-text41 Body-S">
                            Arduino
                          </span>
                          <span className="services-text42 Body-S">
                            programming C++, wiring of electronics,
                            communicating between input &amp; outputs
                          </span>
                        </div>
                      </li>
                      <li
                        data-thq="thq-dropdown"
                        className="services-dropdown12 list-item"
                      >
                        <div
                          data-thq="thq-dropdown-toggle"
                          className="services-dropdown-toggle16"
                        >
                          <span className="services-text43 Body-S">
                            Web / Visuals
                          </span>
                          <span className="services-text44 Body-S">
                            HTML + CSS, p5.js, Processing, web scraping
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="services-col4-container2">
                  <div
                    data-thq="thq-dropdown"
                    className="services-standard Body-M list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="services-dropdown-toggle17"
                    >
                      <span className="Body-M">Standard</span>
                      <div data-thq="thq-dropdown-arrow">
                        <svg
                          viewBox="0 0 1024 1024"
                          className="services-icon08"
                        >
                          <path d="M426 726v-428l214 214z"></path>
                        </svg>
                      </div>
                    </div>
                    <ul
                      data-thq="thq-dropdown-list"
                      className="services-dropdown-list4"
                    >
                      <li
                        data-thq="thq-dropdown"
                        className="services-dropdown13 list-item"
                      >
                        <div
                          data-thq="thq-dropdown-toggle"
                          className="services-dropdown-toggle18"
                        >
                          <span className="services-text46 Body-M">
                            $50 / hour (min. 2 hours)
                          </span>
                        </div>
                      </li>
                      <li
                        data-thq="thq-dropdown"
                        className="services-dropdown14 list-item"
                      >
                        <div
                          data-thq="thq-dropdown-toggle"
                          className="services-dropdown-toggle19"
                        >
                          <span className="services-text47 Body-S">
                            Consultation
                          </span>
                          <span className="services-text48 Body-S">
                            Handover
                          </span>
                          <span className="services-text49 Body-S">
                            Setup (optional)
                          </span>
                          <span className="services-text50 Body-S">
                            Maintenance &amp; Repair (optional)
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    data-thq="thq-dropdown"
                    className="services-emergency Body-M list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="services-dropdown-toggle20"
                    >
                      <span className="Body-M">Emergency Hotline</span>
                      <div data-thq="thq-dropdown-arrow">
                        <svg
                          viewBox="0 0 1024 1024"
                          className="services-icon10"
                        >
                          <path d="M426 726v-428l214 214z"></path>
                        </svg>
                      </div>
                    </div>
                    <ul
                      data-thq="thq-dropdown-list"
                      className="services-dropdown-list5"
                    >
                      <li
                        data-thq="thq-dropdown"
                        className="services-dropdown15 list-item"
                      >
                        <div
                          data-thq="thq-dropdown-toggle"
                          className="services-dropdown-toggle21"
                        >
                          <span className="services-text52 Body-M">
                            $70 / hour (not incl. materials)
                          </span>
                        </div>
                      </li>
                      <li
                        data-thq="thq-dropdown"
                        className="services-dropdown16 list-item"
                      >
                        <div
                          data-thq="thq-dropdown-toggle"
                          className="services-dropdown-toggle22"
                        >
                          <span className="services-text53 Body-S">
                            No prior consultation, same day / next day fix for
                            cold projects (excluding Sundays).
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services-row2-container1">
            <div className="services-col1-container1">
              <span className="services-text54 Title-L">Writing</span>
            </div>
            <div className="services-col234-container1">
              <div className="services-row1-container2">
                <div className="services-col2-container06">
                  <span className="services-text55 Body-L">Exhibition</span>
                </div>
              </div>
              <div className="services-row2-container2">
                <div className="services-col2-container07">
                  <span className="services-text56 Body-M">
                    We craft thoughtful &amp; researched texts for exhibitions
                    and exhibition-adjacent events, including essays, poetry,
                    fiction, and interviews.
                  </span>
                </div>
                <div className="services-col3-container3">
                  <span className="services-text57 Body-M">
                    500 – 3,000 words
                  </span>
                </div>
                <div className="services-col4-container3">
                  <span className="services-text58 Body-M">From $500</span>
                  <span className="services-text59 Body-M">Expedite $100</span>
                </div>
              </div>
              <div className="services-row3-container1">
                <div className="services-col2-container08">
                  <span className="services-text60 Body-L">Grant</span>
                </div>
              </div>
              <div className="services-row4-container1">
                <div className="services-col2-container09">
                  <span className="services-text61 Body-M">
                    We help write funding applications for creative projects and
                    initiatives to realise your ideas, needs, and desires. Our
                    approach is strategic and dedicated, with an emphasis on
                    centring your creative vision and securing fair wages for
                    all.
                  </span>
                </div>
                <div className="services-col3-container4"></div>
                <div className="services-col4-container4">
                  <span className="services-text62 Body-M">
                    10% of total grant budget (min. $250)
                  </span>
                  <span className="services-text63 Body-M">Expedite $200</span>
                </div>
              </div>
              <div className="services-row5-container1">
                <div className="services-col2-container10">
                  <span className="services-text64 Body-L">
                    Artist Statement
                  </span>
                </div>
              </div>
              <div className="services-row6-container1">
                <div className="services-col2-container11">
                  <span className="services-text65 Body-M">
                    We support artists in articulating their artistic intentions
                    and visions with sincerity, elegance, and an awareness of
                    audience. It can be difficult to word a complex idea and/or
                    feeling, so we’re here for you.
                  </span>
                </div>
                <div className="services-col3-container5">
                  <span className="services-text66 Body-M">
                    100 – 500 words
                  </span>
                </div>
                <div className="services-col4-container5">
                  <span className="services-text67 Body-M">$60 – $120</span>
                  <span className="services-text68 Body-M">Expedite $100</span>
                </div>
              </div>
            </div>
          </div>
          <div className="services-row3-container2">
            <div className="services-col1-container2">
              <span className="services-text69 Title-L">Workshops</span>
            </div>
            <div className="services-col234-container2">
              <div className="services-row1-container3">
                <div className="services-col2-container12">
                  <Link to="/kinetic-birds" className="services-navlink Body-L">
                    Kinetic Birds
                  </Link>
                </div>
              </div>
              <div className="services-row2-container3">
                <div className="services-col2-container13">
                  <span className="services-text70 Body-M">
                    Kinetic Birds is a beginner-friendly art and tech workshop
                    which imparts introductory and practical knowledge about
                    Arduino.
                  </span>
                </div>
                <div className="services-col3-container6">
                  <Link to="/kinetic-birds" className="services-navlink1">
                    <div className="services-info">
                      <span className="services-text71 Body-M">
                        Workshop Info
                      </span>
                      <svg viewBox="0 0 1024 1024" className="services-icon12">
                        <path d="M426 726v-428l214 214z"></path>
                      </svg>
                    </div>
                  </Link>
                </div>
                <div className="services-col4-container6">
                  <span className="services-text72 Body-M">
                    From $70 per person
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="services-footer-container">
        <Footer rootClassName="footer-root-class-name"></Footer>
      </div>
    </div>
  )
}

export default Services
