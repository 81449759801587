import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './totapo.css'

const Totapo = (props) => {
  return (
    <div className="totapo-container">
      <Helmet>
        <title>Totapo - Feelers 2024</title>
        <meta property="og:title" content="Totapo - Feelers 2024" />
      </Helmet>
      <div className="totapo-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="totapo-body">
        <div className="totapo-row1">
          <img src="/totapo/t-1.webp" />
          <img src="/work/totapo.webp" />
          <img src="/totapo/t-2.webp" className="totapo-img3" />
        </div>
        <div className="totapo-row2">
          <div className="totapo-left">
            <span className="totapo-text">
              Join us for the launch edition of totapo, a two-week art &amp;
              technology residency that gathers artists, technologists, and
              students for a process of exchange, dialogue, and collaboration.
            </span>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSd4TJp9h3y2b4DcMZvS-PWaoaJ72CDq-Z9eEswsIUu1A_gmKQ/viewform?usp=sf_link"
              target="_blank"
              rel="noreferrer noopener"
              className="totapo-link"
            >
              Sign Up
            </a>
            <img src="/totapo/t-3.webp" className="totapo-image" />
          </div>
          <div className="totapo-right">
            <span className="totapo-text01">14 – 25 August 2023</span>
            <span className="totapo-text02">Mondays – Fridays, 10am – 6pm</span>
            <span className="totapo-text03">@ Potato Productions</span>
            <span className="totapo-text04">284 River Valley Road</span>
            <span className="totapo-text05">Singapore 238325</span>
            <span className="totapo-text06">
              Attendance for all indicated days and times are compulsory.
            </span>
            <span className="totapo-text07">Residency Fees &amp; Budget</span>
            <span className="totapo-text08">
              Participation in the launch edition of Totapo is free-of-charge.
              As we have a limited budget, we can only provide remuneration for
              Self-Employed Persons without a full-time salary.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="totapo-text09">
              We aim to provide $2,500 for the SEP’s participation for the two
              weeks.
            </span>
          </div>
        </div>
        <div className="totapo-row3">
          <span className="totapo-text10">
            From 14 – 25 August, art &amp; tech label Feelers, together with
            digital storytelling studio Tusitala, will facilitate a series of
            in-person workshops and discussions surrounding the topic of
            communal experiences and sharing.
          </span>
          <div className="totapo-img">
            <img src="/totapo/t-4.webp" className="totapo-image1" />
          </div>
        </div>
        <div className="totapo-row4">
          <span className="totapo-text11">
            Driven by mischief and curiosity, participants in the inaugural
            edition of Totapo will explore questions such as:
          </span>
        </div>
        <div className="totapo-row5">
          <div className="totapo-left1">
            <div className="totapo-img1 totapo-img1">
              <img src="/totapo/t-5.webp" className="totapo-image2" />
            </div>
            <span className="totapo-text12">
              How has the Internet reimagined notions of community?
            </span>
            <img src="/totapo/t-7.webp" className="totapo-image3" />
          </div>
          <div className="totapo-centre">
            <span className="totapo-text13">
              How can technology enable us to engage constructively with
              difference?
            </span>
            <span className="totapo-text14">
              What does the future of a sharing economy look like?
            </span>
          </div>
          <div className="totapo-right1">
            <img src="/totapo/t-6.webp" className="totapo-image4" />
            <span className="totapo-text15">
              What is the story of the Internet that we’ve written, and are
              there shared histories or narratives that we’ve overlooked?
            </span>
            <img src="/totapo/t-8.webp" className="totapo-image5" />
            <span className="totapo-text16">
              How might a group function as a decentralised autonomous
              organisation, where work and decision making is “share-based”?
            </span>
          </div>
        </div>
        <div className="totapo-row6">
          <div className="totapo-left2">
            <span className="totapo-text17">
              Totapo draws upon a number of existing formats, namely artist
              residencies, jams, and hackathons. What results is a loose, open
              bracket of time where participants make collective decisions
              around what they will explore, research, discuss, and potentially
              test out or build. The self-directed residency process is
              supported by a number of facilitated sessions led by Feelers and
              Tusitala – including resource-mapping workshops, excursions, and
              networking sessions.
            </span>
          </div>
          <div className="totapo-centre1">
            <img src="/totapo/t-10.webp" className="totapo-image6" />
          </div>
          <div className="totapo-right2">
            <div className="totapo-img2 totapo-img2">
              <img src="/totapo/t-9.webp" className="totapo-image7" />
            </div>
            <span className="totapo-text18">
              Totapo seeks to sustain long-term networks across the fields of
              art and technology, forming the bedrock of a more innovative and
              boundary-pushing ecosystem. At the end of the in-person residency,
              the group will have explored multiple ideas and pursued one
              in-depth, ending with a public sharing in a collectively
              agreed-upon format.
            </span>
          </div>
        </div>
        <div className="totapo-row7">
          <span className="totapo-text19">
            <span className="totapo-text20">
              As the residency involves three parties — artists, technologists,
              and students — compatibility will be a key factor in our selection
              process.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="totapo-text21"></br>
            <br className="totapo-text22"></br>
            <span className="totapo-text23">
              For this cycle, we aim to house:
            </span>
            <br className="totapo-text24"></br>
            <span className="totapo-text25">– 1 artist/ art collective</span>
            <br className="totapo-text26"></br>
            <span className="totapo-text27">
              – 1 technologist/ tech company
            </span>
            <br className="totapo-text28"></br>
            <span className="totapo-text29">– 1–2 student(s)</span>
            <br className="totapo-text30"></br>
            <br className="totapo-text31"></br>
            <span className="totapo-text32">
              You will be matched with other participants who have meaningful
              overlaps with you.
            </span>
            <br className="totapo-text33"></br>
            <br className="totapo-text34"></br>
            <span className="totapo-text35">
              We will get in touch by 31 May about the results of your
              application.
            </span>
          </span>
        </div>
        <div className="totapo-row8">
          <span className="totapo-text36">
            We are excited to hear from you!
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="totapo-text37">
            Please indicate your interest by 30 April.
          </span>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSd4TJp9h3y2b4DcMZvS-PWaoaJ72CDq-Z9eEswsIUu1A_gmKQ/viewform?usp=sf_link"
            target="_blank"
            rel="noreferrer noopener"
            className="totapo-link1"
          >
            Sign Up
          </a>
        </div>
      </div>
      <div className="totapo-footer-container">
        <Footer rootClassName="footer-root-class-name24"></Footer>
      </div>
    </div>
  )
}

export default Totapo
