import React from "react";
import { Link } from "react-router-dom";
import CookieConsent, { Cookies } from "react-cookie-consent";
import PropTypes from "prop-types";

import "./popup.css";

const Popup = () => {
  return (
    <div>
      <CookieConsent
        buttonText="OK"
        buttonStyle={{
          background: "var(--dl-color-feelers-dark)",
          color: "var(--dl-color-gray-white)",
        }}
        style={{ background: "var(--dl-color-gray-white)" }}
        location="bottom"
        expires={999}
        overlay
      >
        <div className="popup">
          <span className="popup__text Body-S">
            By continuing to browse this website, you consent to the terms of
            our
          </span>
          <Link to="/privacy-policy" className="popup__link Body-S">
            Privacy Policy
          </Link>
          <span className="popup__text  Body-S"> .</span>
        </div>
      </CookieConsent>
    </div>
  );
};

export default Popup;
