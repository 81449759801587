import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import TBNavbar from "../components/tb-navbar";
import TBProgramItem from "../components/tb-program-item";
import Footer from "../components/footer";
import "./tb03-programs.css";

const TB03Programs = (props) => {
  return (
    <div className="tb03-programs-container">
      <Helmet>
        <title>TB03-Programs - Feelers 2024</title>
        <meta property="og:title" content="TB03-Programs - Feelers 2024" />
      </Helmet>
      <div className="tb03-programs-bg-container"></div>
      <div className="tb03-programs-navbar-container">
        <TBNavbar rootClassName="tb-navbar-root-class-name1"></TBNavbar>
      </div>
      <div className="tb03-programs-body-container">
        <div className="tb03-programs-r1">
          <span className="tb03-programs-t1 TB-OCR-XXL">Programs</span>
          <img
            alt="image"
            src="/touchbase/b-arrow.webp"
            className="tb03-programs-i1"
          />
        </div>
        <Link to="/tb03-programs1" className="tb03-programs-navlink">
          <div className="tb03-programs-r2">
            <TBProgramItem
              progNo="01"
              progTitle="LET ME SHOW YOU WHAT I SAW"
              progImgSrc="/touchbase/programs/p-01.webp"
              rootClassName="tb-program-item-root-class-name1"
            ></TBProgramItem>
          </div>
        </Link>
        <Link to="/tb03-programs2" className="tb03-programs-navlink1">
          <div className="tb03-programs-r3">
            <TBProgramItem
              progNo="02"
              progTitle="TOUCH_TIME (T_T)"
              progImgSrc="/touchbase/programs/p-02.webp"
              rootClassName="tb-program-item-root-class-name"
            ></TBProgramItem>
          </div>
        </Link>
        <Link to="/tb03-programs3" className="tb03-programs-navlink2">
          <div className="tb03-programs-r4">
            <TBProgramItem
              progNo="03"
              progTitle="PERFOR.ML"
              progImgSrc="/touchbase/programs/p-03.webp"
              rootClassName="tb-program-item-root-class-name2"
            ></TBProgramItem>
          </div>
        </Link>
        <Link to="/tb03-programs4" className="tb03-programs-navlink3">
          <div className="tb03-programs-r5">
            <TBProgramItem
              progNo="04"
              progTitle="RANDOM ACCESS MEMORY"
              progImgSrc="/touchbase/programs/p-04.webp"
              rootClassName="tb-program-item-root-class-name3"
            ></TBProgramItem>
          </div>
        </Link>
        <Link to="/tb03-programs5" className="tb03-programs-navlink4">
          <div className="tb03-programs-r6">
            <TBProgramItem
              progNo="05"
              progTitle="HOW TO GO ON"
              progImgSrc="/touchbase/programs/p-05.webp"
              rootClassName="tb-program-item-root-class-name4"
            ></TBProgramItem>
          </div>
        </Link>
      </div>
      <div className="tb03-programs-footer-container">
        <Footer rootClassName="footer-root-class-name34"></Footer>
      </div>
    </div>
  );
};

export default TB03Programs;
