import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './interactive-rotary-phone.css'

const InteractiveRotaryPhone = (props) => {
  return (
    <div className="interactive-rotary-phone-container">
      <Helmet>
        <title>Interactive-Rotary-Phone - Feelers 2024</title>
        <meta
          property="og:title"
          content="Interactive-Rotary-Phone - Feelers 2024"
        />
      </Helmet>
      <div className="interactive-rotary-phone-bg-container">
        <img
          src="/feelers/bgcircle.png"
          className="interactive-rotary-phone-image"
        />
        <img
          src="/feelers/bgcircle.png"
          className="interactive-rotary-phone-image1"
        />
        <img
          src="/feelers/bgcircle.png"
          className="interactive-rotary-phone-image2"
        />
      </div>
      <div className="interactive-rotary-phone-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="interactive-rotary-phone-body-container">
        <div className="interactive-rotary-phone-left-container">
          <h1 className="interactive-rotary-phone-title Title-XXL">
            Interactive Rotary Phone
          </h1>
          <div className="interactive-rotary-phone-img-container">
            <img
              src="/rotaryphone/ttnw-1.webp"
              className="interactive-rotary-phone-image3"
            />
            <img
              src="/rotaryphone/ttnw-2.webp"
              className="interactive-rotary-phone-image4"
            />
            <div className="interactive-rotary-phone-iframe-container">
              <div className="interactive-rotary-phone-container1">
                <div className="interactive-rotary-phone-container2">
                  <Script
                    html={`<iframe width="100%" height="100%" src="https://www.youtube.com/embed/VRH3qGQ4b14" title="Interactive Rotary Phone | Through the Neighbour's Walls" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`}
                  ></Script>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="interactive-rotary-phone-right-container">
          <div className="interactive-rotary-phone-body-container1">
            <div className="interactive-rotary-phone-row1-container">
              <span className="interactive-rotary-phone-text Body-XS">
                Feelers, Interactive Rotary Phone, 2022. Prop within public
                installation (repurposed rotary phone, Teensy 4.0 with audio
                shield, button). 15cm (H) x 20cm (W) x 20cm (D). Installation
                view, Through the Neighbour&apos;s Walls, by Clarice Ng and
                Junior Foong, curated by The Everyday Museum, at Kreta Ayer
                Square, Singapore, 2022. Courtesy of the curator and the
                artists. Photo by Marcus Chow. Video by Ashley Hi.
              </span>
              <h1 className="interactive-rotary-phone-text01 Body-L">
                <span>Through the Neighbour&apos;s Walls</span>
                <br></br>
                <span>Kreta Ayer Square</span>
                <br></br>
                <span>10 – 14 November 2022</span>
              </h1>
            </div>
            <div className="interactive-rotary-phone-row2-container">
              <div className="interactive-rotary-phone-col1-container"></div>
              <div className="interactive-rotary-phone-col2-container">
                <span className="interactive-rotary-phone-text07 Body-M">
                  Feelers created an interactive rotary phone prop for Through
                  the Neighbour&apos;s Walls, an experiential public
                  installation which is an extension of the first episode of The
                  Everyday Museum’s self-guided audio trail series, Speaking of
                  which – An Organic Terminus.
                </span>
                <span className="interactive-rotary-phone-text08 Body-M">
                  <span>
                    Set in a living room from the past, a Rediffusion set
                    broadcasts familiar programmes throughout the day, reliving
                    its role as a companion to the worker and the homemaker, the
                    young and the old. It alludes to a time when Rediffusion was
                    woven into the fabric of our daily lives and served as a
                    locus around which communities would gather.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    When passers-by listen to the Rediffusion broadcasts, they
                    are transported to a different time and space. For the
                    curious guest, entering the living room takes them into the
                    life of an imagined household where they would engage in
                    intimate encounters. As they discover traces left behind by
                    family members, they will be invited to reflect upon and
                    share their own fond memories of everyday pastimes.
                  </span>
                </span>
                <span className="interactive-rotary-phone-text13 Body-M">
                  Commissioned by The Everyday Museum in partnership with the
                  National Archives of Singapore and with support from
                  Rediffusion. Public interactions by Clarice Ng, set design and
                  art direction by Junior Foong.
                </span>
                <a
                  href="https://www.theeverydaymuseum.sg/event/through-the-neighbours-walls/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="interactive-rotary-phone-link Body-M"
                >
                  Find out more about the installation here.
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="interactive-rotary-phone-footer-container">
        <Footer rootClassName="footer-root-class-name"></Footer>
      </div>
    </div>
  )
}

export default InteractiveRotaryPhone
