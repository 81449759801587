import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import TBNavbar from "../components/tb-navbar";
import TBProgramItem from "../components/tb-program-item";
import "./tb03-programs2.css";

const TB03Programs2 = (props) => {
  return (
    <div className="tb03-programs2-container">
      <Helmet>
        <title>TB03-Programs2 - Feelers 2024</title>
        <meta property="og:title" content="TB03-Programs2 - Feelers 2024" />
      </Helmet>
      <div className="tb03-programs2-bg-container"></div>
      <div className="tb03-programs2-navbar-container">
        <TBNavbar rootClassName="tb-navbar-root-class-name9"></TBNavbar>
      </div>
      <div className="tb03-programs2-body-container">
        <div className="tb03-programs2-r1">
          <span className="tb03-programs2-t1 TB-OCR-XXL">Programs</span>
          <img
            alt="image"
            src="/touchbase/b-arrow.webp"
            className="tb03-programs2-i1"
          />
        </div>
        <div className="tb03-programs2-r2">
          <TBProgramItem
            progNo="01"
            progTitle="LET ME SHOW YOU WHAT I SAW"
            progImgSrc="/touchbase/programs/p-01.webp"
            rootClassName="tb-program-item-root-class-name5"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs2-r3">
          <TBProgramItem
            progNo="02"
            progTitle="TOUCH_TIME (T_T)"
            progImgSrc="/touchbase/programs/p-02.webp"
            rootClassName="tb-program-item-root-class-name40"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs2-r4">
          <TBProgramItem
            progNo="03"
            progTitle="PERFOR.ML"
            progImgSrc="/touchbase/programs/p-03.webp"
            rootClassName="tb-program-item-root-class-name41"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs2-r5">
          <TBProgramItem
            progNo="04"
            progTitle="RANDOM ACCESS MEMORY"
            rootClassName="tb-program-item-root-class-name42"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs2-r6">
          <TBProgramItem
            progNo="05"
            progTitle="HOW TO GO ON"
            rootClassName="tb-program-item-root-class-name43"
          ></TBProgramItem>
        </div>
      </div>
      <div className="tb03-programs2-pop-container">
        <div className="tb03-programs2-c1">
          <Link to="/tb03-programs" className="tb03-programs2-link TB-F-L">
            X
          </Link>
          <span className="tb03-programs2-text TB-OCR-M">ABOUT</span>
          <span className="tb03-programs2-text01 TB-F-S">
            <span>
              The Feelers team is proud to present Cards Against Technology, a
              100% original and not-at-all-ripped-off card game that we need
              your help to finish making. Come get in touch with us, make
              friends, and write your own white cards to play in response to
              prompts.
            </span>
            <br></br>
            <br></br>
            <span>
              Totally normal, very fun, and super casual hangout activities
              include: exploring our fears around technology, speculating wildly
              about what the future might bring, and playing a card game may or
              may not bear passing resemblance to Cards Against Humanity. Oh,
              and probably some fun snacks and tea.
            </span>
            <br></br>
            <br></br>
            <span>
              Not sure where to start with tech? Afraid, confused, or lonely?
              Just wanna hang out? We’re here. Drop in anytime from 2pm to 6pm
              on either of these dates: 24th March and 5th May .
            </span>
          </span>
          <img
            alt="image"
            src="/touchbase/programs/p-02.webp"
            className="tb03-programs2-image"
          />
        </div>
        <div className="tb03-programs2-c2">
          <Link to="/tb03-programs" className="tb03-programs2-navlink TB-F-L">
            X
          </Link>
          <span className="tb03-programs2-text09 TB-OCR-S">
            <span>24 MARCH 2024 &amp; 5 MAY 2024</span>
            <br></br>
            <span>2PM–6PM</span>
            <br></br>
            <span>POTATO PRODUCTIONS</span>
            <br></br>
            <span>284 RIVER VALLEY ROAD</span>
            <br></br>
            <span>SINGAPORE 238325</span>
          </span>
          <span className="tb03-programs2-text19 TB-OCR-XL">
            TOUCH _ TIME (T_T)
          </span>
          <a
            href="https://www.eventbrite.com/e/touch-time-t-t-tickets-846883710977?aff=oddtdtcreator"
            target="_blank"
            rel="noreferrer noopener"
            className="tb03-programs2-link1 TB-OCR-S"
          >
            GET TICKETS
          </a>
        </div>
      </div>
    </div>
  );
};

export default TB03Programs2;
