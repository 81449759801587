import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Profile from '../components/profile'
import Footer from '../components/footer'
import './caterfeelers.css'

const Caterfeelers = (props) => {
  return (
    <div className="caterfeelers-container">
      <Helmet>
        <title>caterfeelers - Feelers 2024</title>
        <meta property="og:title" content="caterfeelers - Feelers 2024" />
      </Helmet>
      <div className="caterfeelers-bg-container">
        <img src="/feelers/bgcircle.png" className="caterfeelers-image" />
        <img src="/feelers/bgcircle.png" className="caterfeelers-image01" />
        <img src="/feelers/bgcircle.png" className="caterfeelers-image02" />
      </div>
      <div className="caterfeelers-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="caterfeelers-body-container">
        <div className="caterfeelers-left-container">
          <h1 className="caterfeelers-title Title-XXL">caterfeelers</h1>
          <div className="caterfeelers-img-container">
            <img
              src="/caterfeelers/cat-1.webp"
              className="caterfeelers-image03"
            />
            <div className="caterfeelers-col2">
              <img
                src="/caterfeelers/cat-2.webp"
                className="caterfeelers-image04"
              />
              <img
                src="/caterfeelers/cat-3.webp"
                className="caterfeelers-image05"
              />
              <img
                src="/caterfeelers/cat-4.webp"
                className="caterfeelers-image06"
              />
            </div>
            <div className="caterfeelers-col21">
              <img
                src="/caterfeelers/cat-9.webp"
                className="caterfeelers-image07"
              />
              <img
                src="/caterfeelers/cat-10.webp"
                className="caterfeelers-image08"
              />
            </div>
            <div className="caterfeelers-col22">
              <img
                src="/caterfeelers/cat-11.webp"
                className="caterfeelers-image09"
              />
              <img
                src="/caterfeelers/cat-12.webp"
                className="caterfeelers-image10"
              />
            </div>
          </div>
        </div>
        <div className="caterfeelers-right-container">
          <div className="caterfeelers-header-container">
            <h1 className="caterfeelers-text Body-L">
              Kinetic jewelry for city dreaming and roaming.
            </h1>
          </div>
          <div className="caterfeelers-body-container1">
            <div className="caterfeelers-row1-container">
              <div className="caterfeelers-left-container1"></div>
              <span className="caterfeelers-text1">
                <span className="Body-M">
                  caterfeelers is a kinetic jewelry brand under artist label
                  Feelers which melds electronics and jewelry into distinctive
                  wearables. It currently produces experimental &amp; DIY
                  designs made by hand.
                </span>
                <span>
                   Its first collection, launched in 2022, is 4 o&apos;clock
                  ___________.
                </span>
              </span>
            </div>
            <div className="caterfeelers-row2-container">
              <a
                href="https://kinopio.club/caterfeelers-4-o-clock--gARHXNgvYQWTTKfq4VOp_"
                target="_blank"
                rel="noreferrer noopener"
                className="caterfeelers-link Body-M"
              >
                Shop caterfeelers
              </a>
              <div className="caterfeelers-col2-container">
                <span className="caterfeelers-text4 Body-M">
                  4 o&apos;clock ___________ is your bodily companion as you
                  traverse the city, busy living your many lives. Naive and
                  sincere, it sticks close to you, open to touch and shifts.
                  Perhaps it will be a good distraction. Perhaps it will be a
                  friend for you wherever you go.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="caterfeelers-row3-container">
              <div className="caterfeelers-col1-container">
                <Profile
                  profileImg="/caterfeelers/cat-5.webp"
                  profileTitle="brrr"
                  profileName=" "
                  rootClassName="profile-root-class-name"
                ></Profile>
              </div>
              <div className="caterfeelers-col2-container1">
                <span className="caterfeelers-text5 Body-M">
                  brrr is a gentle and imaginative blessing for your city romps.
                  Made with omamori and mizuhiki knots, brrr offers well wishes
                  for protection, togetherness and connection in the metropolis.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="caterfeelers-row3-container1">
              <div className="caterfeelers-col1-container1">
                <Profile
                  profileImg="/caterfeelers/cat-6.webp"
                  profileTitle="click-click"
                  profileName=" "
                  rootClassName="profile-root-class-name"
                ></Profile>
              </div>
              <div className="caterfeelers-col2-container2">
                <span className="caterfeelers-text6 Body-M">
                  click-click is your low-res third eye for another point of
                  view. Its camera centrepiece hangs upon a string of flowers,
                  and comes with three acrylic filters – grape, ultramarine and
                  neon yellow.
                </span>
              </div>
            </div>
            <div className="caterfeelers-row4-container">
              <div className="caterfeelers-col1-container2">
                <Profile
                  profileImg="/caterfeelers/cat-7.webp"
                  profileTitle="swish"
                  profileName=" "
                  rootClassName="profile-root-class-name"
                ></Profile>
              </div>
              <div className="caterfeelers-col2-container3">
                <span className="caterfeelers-text7 Body-M">
                  swish is your versatile charm necklace-meets-evolved fidget
                  spinner. Comes in a half-and-half design of wire and charms
                  you can unhook and hook along the necklace as you wish. The
                  mini fan is activated by the push of a button.
                </span>
              </div>
            </div>
            <div className="caterfeelers-row5-container">
              <div className="caterfeelers-col1-container3">
                <Profile
                  profileImg="/caterfeelers/cat-8.webp"
                  profileTitle="flutter"
                  profileName=" "
                  rootClassName="profile-root-class-name"
                ></Profile>
              </div>
              <div className="caterfeelers-col2-container4">
                <span className="caterfeelers-text8 Body-M">
                  flutter is your light-up mask chain with small LED companions
                  on either side. Press either/both buttons to delight friends,
                  send morse code messages, and brighten your urban days.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="caterfeelers-footer-container">
        <Footer rootClassName="footer-root-class-name"></Footer>
      </div>
    </div>
  )
}

export default Caterfeelers
