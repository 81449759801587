import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Profile from "../components/profile";
import Footer from "../components/footer";
import "./education.css";

const Education = (props) => {
  return (
    <div className="education-container">
      <Helmet>
        <title>Education - Feelers 2024</title>
        <meta property="og:title" content="Education - Feelers 2024" />
      </Helmet>
      <div className="education-bg-container">
        <img src="/feelers/bgcircle.png" className="education-image" />
        <img src="/feelers/bgcircle.png" className="education-image1" />
        <img src="/feelers/bgcircle.png" className="education-image2" />
      </div>
      <div className="education-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="education-body-container">
        <div className="education-left-container">
          <h1 className="education-title Title-XXL">Education</h1>
          <span className="education-text">
            We design, teach, and facilitate art &amp; tech programmes that are
            cross-disciplinary, current, and expansive. Our pedagogical approach
            is collaborative and inquisitive, emphasising mutual exchange and
            artistic research.
          </span>
        </div>
        <div className="education-right-container">
          <div className="education-body-container1">
            <div className="education-row1-container">
              <div className="education-left-container1"></div>
            </div>
            <div className="education-row2-container">
              <div className="education-left-container2"></div>
            </div>
            <div className="education-row3-container">
              <div className="education-col1-container"></div>
              <div className="education-col2-container">
                <Link to="/soai-main" className="education-navlink">
                  <div className="education-ads-container">
                    <Profile
                      profileImg="/soai/soai-logo-1500w.webp"
                      profileTitle="School of Alternate Internets"
                      profileName=" "
                      rootClassName="profile-root-class-name25"
                      profileBio=" "
                      profileInterests=" "
                    ></Profile>
                  </div>
                </Link>
                <div className="education-voices-container">
                  <Profile
                    profileImg="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
                    profileTitle="Kinetic Birds"
                    profileName="xx"
                    rootClassName="profile-root-class-name26"
                  ></Profile>
                </div>
                <div className="education-distance-container">
                  <Profile
                    profileImg="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
                    profileTitle="Sketch2pigeon"
                    profileName="xx"
                    rootClassName="profile-root-class-name27"
                  ></Profile>
                </div>
                <div className="education-office-container">
                  <Profile
                    profileImg="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
                    profileTitle="Writing Machine"
                    profileName="xx"
                    rootClassName="profile-root-class-name28"
                  ></Profile>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="education-footer-container">
        <Footer rootClassName="footer-root-class-name32"></Footer>
      </div>
    </div>
  );
};

export default Education;
