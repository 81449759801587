import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Profile from '../components/profile'
import Footer from '../components/footer'
import './labs.css'

const Labs = (props) => {
  return (
    <div className="labs-container">
      <Helmet>
        <title>Labs - Feelers 2024</title>
        <meta property="og:title" content="Labs - Feelers 2024" />
      </Helmet>
      <div className="labs-bg-container">
        <img src="/feelers/bgcircle.png" className="labs-image" />
        <img src="/feelers/bgcircle.png" className="labs-image1" />
        <img src="/feelers/bgcircle.png" className="labs-image2" />
      </div>
      <div className="labs-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="labs-body-container">
        <div className="labs-left-container">
          <h1 className="labs-title Title-XXL">Labs</h1>
        </div>
        <div className="labs-right-container">
          <div className="labs-body-container1">
            <div className="labs-row1-container">
              <div className="labs-left-container1"></div>
              <span className="labs-text Body-M">
                Labs is a pilot programme for projects that trace unusual and
                innovative lines between art and technology. For 2021-22, we
                focus on integrating performance and technology, with an
                emphasis on how liveness and audience experience can be remapped
                across digital, virtual and physical space.
              </span>
            </div>
            <div className="labs-row2-container">
              <div className="labs-left-container2">
                <span className="labs-text01 Body-S">
                  <span>Yun Teng</span>
                  <br></br>
                  <span>Documentation Lead 2021</span>
                  <br></br>
                </span>
              </div>
              <span className="labs-text06 Body-M">
                <span>
                  Our Labs projects in 2021 place a focus on proposals that
                  integrate performance and technology, testing how liveness and
                  audience experience can be remapped across digital, virtual
                  and physical space. With an emphasis on playtesting,
                  experimentation and development, documentation becomes an
                  important way to trace the process and evolution of each Lab
                  project. Beyond the material outcomes, we place value on the
                  processual, social, emotional, virtual and immaterial aspects
                  of the Labs.
                </span>
                <br></br>
                <br></br>
                <span>
                  These are the driving considerations behind the development of
                  this growing, open (and incomplete) archive. This page is an
                  exercise in creating a constellation of materials and drawing
                  connections between the different ideas, actions, objects and
                  traces of each project. In doing so, it reflects, responds to,
                  and unfolds their processes. It also attempts to think about
                  the ways in which archival documentation can be activated, and
                  how sites of interpretation can be generated.
                </span>
              </span>
            </div>
            <div className="labs-row3-container">
              <div className="labs-col1-container"></div>
              <div className="labs-col2-container">
                <div className="labs-ads-container">
                  <Profile
                    profileImg="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
                    profileTitle="100 Advertisements - Now Online!"
                    profileName="Eng Kai Er"
                    rootClassName="profile-root-class-name"
                  ></Profile>
                </div>
                <div className="labs-voices-container">
                  <Profile
                    profileImg="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
                    profileTitle="voices in a room"
                    profileName="Ang Kia Yee, ants chua, Chew Shaw En, Helen Cai, Rice Tan, txting teo"
                    rootClassName="profile-root-class-name"
                  ></Profile>
                </div>
                <div className="labs-distance-container">
                  <Profile
                    profileImg="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
                    profileTitle="Across a Small Distance"
                    profileName="Chew Shaw En, Jevon Chandra"
                    rootClassName="profile-root-class-name"
                  ></Profile>
                </div>
                <div className="labs-office-container">
                  <Profile
                    profileImg="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
                    profileTitle="Office: Hands Edition"
                    profileName="Xafiér Yap"
                    rootClassName="profile-root-class-name"
                  ></Profile>
                </div>
                <div className="labs-souvenir-container">
                  <Profile
                    profileImg="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
                    profileTitle="Souvenir"
                    profileName="Ashley Hi, Hilary Yip, Kirti Upadhyaya, Nathanael Townsend, Ryan Benjamin Lee"
                    rootClassName="profile-root-class-name"
                  ></Profile>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="labs-footer-container">
        <Footer rootClassName="footer-root-class-name"></Footer>
      </div>
    </div>
  )
}

export default Labs
