import React from 'react'

import PropTypes from 'prop-types'

import './profile.css'

const Profile = (props) => {
  return (
    <div className={`profile-profile ${props.rootClassName} `}>
      <img src={props.profileImg} className="profile-image" />
      <span className="profile-title Ttile-S">{props.profileTitle}</span>
      <span className="profile-name Body-S">{props.profileName}</span>
      <span className="profile-bio Body-S">{props.profileBio}</span>
      <span className="profile-interests Body-S">{props.profileInterests}</span>
    </div>
  )
}

Profile.defaultProps = {
  profileInterests: 'Interests: vintage furniture, walking, repetition',
  profileBio:
    'Kia Yee operates across disciplines as a poet and theatre-maker, cultivating projects that are slow, intimately surreal, and transdisciplinary.',
  rootClassName: '',
  profileName: 'Ang Kia Yee',
  profileTitle: 'Company Lead',
  profileImg: 'https://play.teleporthq.io/static/svg/placeholders/no-image.svg',
}

Profile.propTypes = {
  profileInterests: PropTypes.string,
  profileBio: PropTypes.string,
  rootClassName: PropTypes.string,
  profileName: PropTypes.string,
  profileTitle: PropTypes.string,
  profileImg: PropTypes.string,
}

export default Profile
