import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Profile from '../components/profile'
import Footer from '../components/footer'
import './greener-screens.css'

const GreenerScreens = (props) => {
  return (
    <div className="greener-screens-container">
      <Helmet>
        <title>Greener-Screens - Feelers 2024</title>
        <meta property="og:title" content="Greener-Screens - Feelers 2024" />
      </Helmet>
      <div className="greener-screens-bg-container">
        <img src="/feelers/bgcircle.png" className="greener-screens-image" />
        <img src="/feelers/bgcircle.png" className="greener-screens-image1" />
        <img src="/feelers/bgcircle.png" className="greener-screens-image2" />
      </div>
      <div className="greener-screens-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="greener-screens-body-container">
        <div className="greener-screens-left-container">
          <h1 className="greener-screens-title Title-XXL">Greener Screens</h1>
          <div className="greener-screens-img-container">
            <img
              src="/greenerscreens/gs-1.webp"
              className="greener-screens-image3"
            />
            <div className="greener-screens-col2">
              <img
                src="/greenerscreens/gs-2.webp"
                className="greener-screens-image4"
              />
              <img
                src="/greenerscreens/gs-3.webp"
                className="greener-screens-image5"
              />
            </div>
            <img
              src="/greenerscreens/gs-4.webp"
              className="greener-screens-image6"
            />
            <div className="greener-screens-col21">
              <img
                src="/greenerscreens/gs-5.webp"
                className="greener-screens-image7"
              />
              <img
                src="/greenerscreens/gs-6.webp"
                className="greener-screens-image8"
              />
            </div>
          </div>
        </div>
        <div className="greener-screens-right-container">
          <div className="greener-screens-body-container1">
            <div className="greener-screens-row1-container">
              <div className="greener-screens-col1-container">
                <span className="greener-screens-text Body-S">
                  <span>library@harbourfront</span>
                  <br className="Body-S"></br>
                  <span className="Body-S">Programme Zone 1</span>
                  <br className="Body-S"></br>
                  <span className="Body-S">3 September 2022</span>
                  <br className="Body-S"></br>
                  <span className="Body-S">1pm – 3pm</span>
                  <br className="Body-S"></br>
                </span>
              </div>
              <div className="greener-screens-col2-container">
                <h1 className="greener-screens-text09 Body-L">
                  Building a Sustainable Tech Toolkit
                </h1>
                <span className="greener-screens-text10 Body-M">
                  <span>
                    Greener Screens is a workshop where Ashley Hi, Tech Lead of
                    Feelers, shares resources on greener tech habits.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    The workshop will cover actionable design steps for greater
                    energy efficiency, existing digital tools that minimise
                    energy consumption, as well as Feelers’ own eco experiments.
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    Participants will be led through creative exercises to build
                    a sustainable tech toolkit that is customised to the needs
                    of their own business or personal practice.
                  </span>
                </span>
              </div>
            </div>
            <div className="greener-screens-row2-container">
              <div className="greener-screens-col1-container1"></div>
              <div className="greener-screens-col2-container1">
                <span className="greener-screens-text18 Body-M">
                  <span>
                    In an effort to make conversations around digital
                    sustainability less daunting, we designed a mad lib green
                    manifesto, and sticker sheet of eco tips to keep things
                    playful.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    Because we had so much fun with it, we are thinking of
                    making our materials (including the full eco tech toolkit
                    content) available to everyone!
                  </span>
                  <br></br>
                  <br></br>
                  <span>Stay tuned on our Instagram for updates.</span>
                </span>
              </div>
            </div>
            <div className="greener-screens-row3-container">
              <div className="greener-screens-col1-container2">
                <Profile
                  profileImg="/profiles/ashley.webp"
                  profileTitle="Workshop Designer &amp; Facilitator"
                  profileName="Ashley Hi"
                  rootClassName="profile-root-class-name"
                ></Profile>
              </div>
              <div className="greener-screens-col2-container2">
                <span className="greener-screens-text26 Body-M">
                  Ashley Hi is a visual artist and creative developer. She
                  believes that art and technology can disrupt binaries of
                  structure and agency to form alternative perspectives and
                  durable solutions.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="greener-screens-footer-container">
        <Footer rootClassName="footer-root-class-name"></Footer>
      </div>
    </div>
  )
}

export default GreenerScreens
