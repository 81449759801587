import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import TBNavbar from "../components/tb-navbar";
import TBProgramItem from "../components/tb-program-item";
import "./tb03-programs3.css";

const TB03Programs3 = (props) => {
  return (
    <div className="tb03-programs3-container">
      <Helmet>
        <title>TB03-Programs3 - Feelers 2024</title>
        <meta property="og:title" content="TB03-Programs3 - Feelers 2024" />
      </Helmet>
      <div className="tb03-programs3-bg-container"></div>
      <div className="tb03-programs3-navbar-container">
        <TBNavbar rootClassName="tb-navbar-root-class-name10"></TBNavbar>
      </div>
      <div className="tb03-programs3-body-container">
        <div className="tb03-programs3-r1">
          <span className="tb03-programs3-t1 TB-OCR-XXL">Programs</span>
          <img
            alt="image"
            src="/touchbase/b-arrow.webp"
            className="tb03-programs3-i1"
          />
        </div>
        <div className="tb03-programs3-r2">
          <TBProgramItem
            progNo="01"
            progTitle="TOUCH_TIME (T_T)"
            progImgSrc="/touchbase/programs/p-01.webp"
            rootClassName="tb-program-item-root-class-name44"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs3-r3">
          <TBProgramItem
            progNo="02"
            progTitle="LET ME SHOW YOU WHAT I SAW"
            progImgSrc="/touchbase/programs/p-02.webp"
            rootClassName="tb-program-item-root-class-name45"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs3-r4">
          <TBProgramItem
            progNo="03"
            progTitle="PERFOR.ML"
            progImgSrc="/touchbase/programs/p-03.webp"
            rootClassName="tb-program-item-root-class-name46"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs3-r5">
          <TBProgramItem
            progNo="04"
            progTitle="RANDOM ACCESS MEMORY"
            rootClassName="tb-program-item-root-class-name47"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs3-r6">
          <TBProgramItem
            progNo="05"
            progTitle="HOW TO GO ON"
            rootClassName="tb-program-item-root-class-name48"
          ></TBProgramItem>
        </div>
      </div>
      <div className="tb03-programs3-pop-container">
        <div className="tb03-programs3-c1">
          <Link to="/tb03-programs" className="tb03-programs3-navlink TB-F-L">
            X
          </Link>
          <span className="tb03-programs3-text TB-OCR-M">ABOUT</span>
          <span className="tb03-programs3-text01 TB-F-S">
            <span>
              A subsea data centre begins its descent to the seabed. Our
              characters scramble to find a way out. They’re faced with a deluge
              of data: secrets leaking from surrounding servers and slips of the
              tongue by counterparts they don&apos;t know if they can trust.
            </span>
            <br></br>
            <br></br>
            <span>
              If they are to escape, they must sort through tangled webs of
              information, and decide whether to stake their survival on the
              systems that led them to the centre in the sea.
            </span>
            <br></br>
            <br></br>
            <span>
              As our team at Feelers has expanded, so has the range of our
              interests and capabilities. We’ve had some untangling of our own
              to do: identifying independent threads of inquiry, spinning them
              out fully to try and weave something new.
            </span>
            <br></br>
            <br></br>
            <span>
              With perfor.ml, we investigate the possibility of bringing machine
              learning into performance—on whose terms, and for what goals? At
              the same time, perfor.ml is our first in-house performance
              project, an emergent exploration of how our individual artistic
              practices can come into sustained contact with each other.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span>
              perfor.ml was developed under the Performing Arts x Tech Lab 2023,
              a new partnership between the National Arts Council and Esplanade
              – Theatres on the Bay, with Keio-NUS CUTE Center as technology
              consultant. Our ongoing research under perfor.ml will be exhibited
              at the Esplanade Annexe Studio in April alongside the work of
              other Lab participants.
            </span>
          </span>
          <img
            alt="image"
            src="/touchbase/programs/p-03.webp"
            className="tb03-programs3-image"
          />
        </div>
        <div className="tb03-programs3-c2">
          <Link to="/tb03-programs" className="tb03-programs3-navlink1 TB-F-L">
            X
          </Link>
          <span className="tb03-programs3-text15 TB-OCR-S">
            <span className="TB-OCR-S tb03-programs3-text16">EXHIBITION</span>
            <br></br>
            <span>5–6 APRIL 2024, 12–10PM</span>
            <br></br>
            <span>11–13 APRIL 2024, 12–8PM</span>
            <br></br>
            <br></br>
            <span>PERFORMANCE</span>
            <br></br>
            <span>13 APRIL 2024, 1–230PM</span>
            <br></br>
            <br></br>
            <span>ESPLANADE ANNEXE STUDIO</span>
            <br></br>
            <span>1 ESPLANADE DR</span>
            <br></br>
            <span>SINGAPORE 038981</span>
            <br></br>
          </span>
          <span className="tb03-programs3-text34 TB-OCR-XL">PERFOR.ML</span>
          <a
            href="https://performl.peatix.com/view"
            target="_blank"
            rel="noreferrer noopener"
            className="tb03-programs3-link TB-OCR-S"
          >
            GET TICKETS
          </a>
        </div>
      </div>
    </div>
  );
};

export default TB03Programs3;
