import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './writing-machine.css'

const WritingMachine = (props) => {
  return (
    <div className="writing-machine-container">
      <Helmet>
        <title>Writing-Machine - Feelers 2024</title>
        <meta
          name="description"
          content="Writing Machine is a workshop where we co-write with other tools, phenomena and intelligences."
        />
        <meta property="og:title" content="Writing-Machine - Feelers 2024" />
        <meta
          property="og:description"
          content="Writing Machine is a workshop where we co-write with other tools, phenomena and intelligences."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84a4fc01-d18f-41cc-894d-d80dd3868105/ffbba21c-2663-4b3a-9e83-8961e045f39f?org_if_sml=1&amp;q=80&amp;force_format=original"
        />
      </Helmet>
      <div className="writing-machine-bg-container">
        <img src="/feelers/bgcircle.png" className="writing-machine-image" />
        <img src="/feelers/bgcircle.png" className="writing-machine-image1" />
        <img src="/feelers/bgcircle.png" className="writing-machine-image2" />
      </div>
      <div className="writing-machine-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="writing-machine-body-container">
        <div className="writing-machine-left-container">
          <h1 className="writing-machine-title Title-XXL">Writing Machine</h1>
          <div className="writing-machine-img-container">
            <img
              src="/writemachine/wm-1-1500w.webp"
              className="writing-machine-image3"
            />
          </div>
        </div>
        <div className="writing-machine-right-container">
          <div className="writing-machine-header-container">
            <h1 className="writing-machine-text Body-L">
              How can we collaborate with other people, species, objects,
              machines, and ghosts? Can we give up control?
            </h1>
          </div>
          <div className="writing-machine-body-container1">
            <div className="writing-machine-row1-container">
              <span className="writing-machine-text01 Body-M">
                <span className="writing-machine-text02">
                  Designed &amp; Facilitated
                </span>
                <br className="writing-machine-text03"></br>
                <span className="writing-machine-text04">by Ang Kia Yee</span>
                <br className="writing-machine-text05"></br>
              </span>
              <span className="writing-machine-text06 Body-M">
                <span>
                  Writing Machine is a workshop where we co-write with other
                  tools, phenomena and intelligences. Through exercises that
                  span the literary, computational and divinatory, participants
                  engage with authorship as inherently shared — with code,
                  chance, constraints, divination, their subconscious, and other
                  human beings.
                </span>
                <br className="Body-M"></br>
              </span>
            </div>
            <div className="writing-machine-row2-container">
              <span className="writing-machine-text09">
                <span className="writing-machine-text10">
                  Saturday, 25 February 2023
                </span>
                <br></br>
                <span>2:00pm – 4:30pm</span>
                <br></br>
                <span>Potato Productions,</span>
                <br></br>
                <span>284 River Valley Road</span>
                <br></br>
              </span>
              <span className="writing-machine-text18 Body-M">
                This 2.5-hr workshop prioritises doing and experiencing over
                thinking and understanding. No experience with creative writing,
                computing and divination is necessary.
              </span>
            </div>
            <div className="writing-machine-row3-container">
              <span className="writing-machine-text19 Body-M">
                <span className="writing-machine-text20">
                  $16-$25/pax sliding scale*
                </span>
                <br className="writing-machine-text21"></br>
              </span>
              <span className="writing-machine-text22 Body-M">
                <span>
                  *We offer a sliding scale to make the workshop more accessible
                  for those who cannot afford to pay more. Please opt for a
                  suitable amount based on your income and living conditions. If
                  you are able to afford more, we ask that you do so. If you are
                  unable to afford this workshop even on this sliding scale,
                  please email us at team@feelers-feelers.com for a free spot.
                </span>
                <br className="Body-M"></br>
              </span>
            </div>
            <div className="writing-machine-row4-container">
              <div className="writing-machine-left-container1"></div>
              <div className="writing-machine-right-container1">
                <a
                  href="https://tinyurl.com/writemachine"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="writing-machine-link Body-M"
                >
                  <span>
                    Sign up
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>now!</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="writing-machine-footer-container">
        <Footer rootClassName="footer-root-class-name23"></Footer>
      </div>
    </div>
  )
}

export default WritingMachine
