import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './streetlamp-no-107101.css'

const StreetlampNo107101 = (props) => {
  return (
    <div className="streetlamp-no107101-container">
      <Helmet>
        <title>Streetlamp-No-107101 - Feelers 2024</title>
        <meta
          property="og:title"
          content="Streetlamp-No-107101 - Feelers 2024"
        />
      </Helmet>
      <div className="streetlamp-no107101-bg-container">
        <img
          src="/feelers/bgcircle.png"
          className="streetlamp-no107101-image"
        />
        <img
          src="/feelers/bgcircle.png"
          className="streetlamp-no107101-image1"
        />
        <img
          src="/feelers/bgcircle.png"
          className="streetlamp-no107101-image2"
        />
      </div>
      <div className="streetlamp-no107101-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="streetlamp-no107101-body-container">
        <div className="streetlamp-no107101-left-container">
          <h1 className="streetlamp-no107101-title Title-XXL">
            <span>
              Streetlamp
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>No. 107101</span>
          </h1>
          <div className="streetlamp-no107101-img-container">
            <img
              src="/work/streetlamp.webp"
              className="streetlamp-no107101-image3"
            />
            <div className="streetlamp-no107101-col2">
              <img
                src="/streetlamp/streetlamp-1.webp"
                className="streetlamp-no107101-image4"
              />
              <img
                src="/streetlamp/streetlamp-2.webp"
                className="streetlamp-no107101-image5"
              />
            </div>
            <div className="streetlamp-no107101-iframe-container">
              <div className="streetlamp-no107101-container1">
                <div className="streetlamp-no107101-container2">
                  <Script
                    html={`<iframe width="100%" height="100%" src="https://www.youtube.com/embed/zucJ7h-OOZI" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`}
                  ></Script>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="streetlamp-no107101-right-container">
          <div className="streetlamp-no107101-body-container1">
            <div className="streetlamp-no107101-row1-container">
              <span className="streetlamp-no107101-text03 Body-XS">
                Feelers, Streetlamp No. 107101, 2022. Mixed media installation
                (website containing text updated live, LED lamp, ultrasonic
                sensors, relay module, ESP8266 wifi board, MDF). 260cm (H) x
                25cm (W) x 25cm (D). Installation view, Tracing Latencies,
                curated by Hoe Su Fern, at 136 GOETHE LAB, Singapore, 2022.
                Courtesy of the curator and the artist. Photo by Charlotte Tan /
                Ashley Hi. Video by Ashley Hi.
              </span>
              <h1 className="streetlamp-no107101-text04 Body-L">
                <span>Tracing Latencies</span>
                <br></br>
                <span>136 Goethe Lab</span>
                <br></br>
                <span>3 March – 3 April 2022</span>
              </h1>
            </div>
            <div className="streetlamp-no107101-row2-container">
              <div className="streetlamp-no107101-col1-container"></div>
              <div className="streetlamp-no107101-col2-container">
                <span className="streetlamp-no107101-text10 Body-M">
                  Streetlamp No. 107101 is a responsive light installation which
                  speaks to the heavy light pollution of our small city, which
                  houses at least 107,100 streetlamps (as of 2017). By resisting
                  our expectations of light, it meditates upon our relationships
                  with visibility as inflected by our personal and communal
                  senses of safety, security, privacy and time. As visibility
                  heightens through data networks which capture and compute our
                  quantified and surveilled selves, how much do we want to be
                  seen/ perceived?
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="streetlamp-no107101-text11 Body-M">
                  The work was created for Tracing Latencies, an exhibition by
                  the Culture &amp; Smart City Project, a multilateral and
                  multidisciplinary collaborative initiative by EUNIC Singapore
                  Cluster, supported by Goethe-Institut. The hybrid group
                  exhibition was held from 3 March to 3 April 2022 at 136 GOETHE
                  LAB, Singapore.
                </span>
                <a
                  href="https://www.goethe.de/prj/csc/en/phase-2/tracing-latencies.html#:~:text=Tracing%20Latencies%20is%20a%20mixed,at%20136%20GOETHE%20LAB%2C%20Singapore.&amp;text=Tracing%20Latencies%20radically%20(re)imagines,urban%20environments%20and%20each%20other."
                  target="_blank"
                  rel="noreferrer noopener"
                  className="streetlamp-no107101-link Body-M"
                >
                  Find out more about the exhibition here.
                </a>
              </div>
            </div>
            <div className="streetlamp-no107101-row3-container">
              <div className="streetlamp-no107101-col1-container1">
                <span className="streetlamp-no107101-text12 Body-M">
                  Light Walking
                </span>
                <span className="streetlamp-no107101-text13 Body-M">
                  <span>companion workshop</span>
                  <br></br>
                  <br></br>
                  <span>136 Goethe Lab</span>
                  <br></br>
                  <span>17 March 2022</span>
                  <br></br>
                  <span>7.30pm – 9.30pm</span>
                </span>
              </div>
              <div className="streetlamp-no107101-col2-container1">
                <span className="streetlamp-no107101-text22 Body-M">
                  <span>
                    What is the darkest route we can take from one point to
                    another?
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    {' '}
                    Singapore has at least 107,100 street lamps on our streets,
                    back alleys and roads. Taking their artwork Streetlamp No.
                    107101 as a starting point, artist collective Feelers will
                    guide you through a walking and mapping process to seek out
                    lesser lit trails.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    You will work in pairs to plot and wander the city anew. By
                    searching for darkness, we drift off habitual pathways to
                    explore, observe, respond and re-orientate within our
                    brightly-lit city.
                  </span>
                </span>
                <a
                  href="https://kinopio.club/-light-walking-1Ie5iyqf-oBGbDmD4cnqw"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="streetlamp-no107101-link1 Body-M"
                >
                  View Ashley&apos;s workshop notes here.
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="streetlamp-no107101-footer-container">
        <Footer rootClassName="footer-root-class-name"></Footer>
      </div>
    </div>
  )
}

export default StreetlampNo107101
