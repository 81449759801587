import React from "react";

import { Helmet } from "react-helmet";

import TBNavbar from "../components/tb-navbar";
import Footer from "../components/footer";
import "./tb04-editorial.css";

const TB04Editorial = (props) => {
  return (
    <div className="tb04-editorial-container">
      <Helmet>
        <title>TB04-Editorial - Feelers 2024</title>
        <meta property="og:title" content="TB04-Editorial - Feelers 2024" />
      </Helmet>
      <div className="tb04-editorial-bg-container"></div>
      <div className="tb04-editorial-navbar-container">
        <TBNavbar rootClassName="tb-navbar-root-class-name2"></TBNavbar>
      </div>
      <div className="tb04-editorial-body-container">
        <div className="tb04-editorial-r1">
          <span className="tb04-editorial-t1 TB-OCR-XXL">Editorial</span>
          <img
            alt="image"
            src="/touchbase/b-arrow.webp"
            className="tb04-editorial-i0"
          />
        </div>
        <div className="tb04-editorial-r2 TB-F-S">
          <div className="tb04-editorial-r2-r1">
            <span className="tb04-editorial-t2 TB-F-XL">
              LITURGY OF THE ARCHIPELAGO
            </span>
            <span className="tb04-editorial-t3 TB-OCR-S">
              BY SHARMINI APHRODITE
            </span>
            <span className="tb04-editorial-t4 TB-F-M">
              <span className="TB-F-M">
                How do you construct the history of an archipelago? There is the
                old saying that an archipelago is not separated by the sea, but
                drawn together by it, joined not by the solidity of land but the
                fluidity of water. The Caribbean writer and philosopher Édouard
                Glissant thought of the archipelago as a multitudinous space in
                which any notions of a centre are extinguished.
              </span>
              <span className="TB-OCR-S tb04-editorial-t3">1</span>
              <span className="TB-F-M"> </span>
              <span className="TB-F-M">
                It is from the archipelagic Philippines that
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <a
                href="https://developh.org"
                target="_blank"
                rel="noreferrer noopener"
                className="links-colours"
              >
                Developh
              </a>
              <span className="TB-F-M">
                , a ‘critical technology institute and community of practice
                reclaiming technology as a tool for liberation rather than
                repression’, emerged. It was founded in 2016 by
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <a
                href="https://chia.design/"
                target="_blank"
                rel="noreferrer noopener"
                className="links-colours"
              >
                Chia Amisola
              </a>
              <span className="TB-F-M">
                , an internet and ambient artist born and raised in Manila who
                now resides in San Francisco. Chia opened our conversation with
                a line of thought that echoed throughout our discussion: ‘the
                history of the internet is the history of the Filipino people.’
              </span>
            </span>
          </div>
          <div className="tb04-editorial-r2-r2">
            <img
              alt="image"
              src="/touchbase/editorial/e-01.webp"
              loading="lazy"
              className="tb04-editorial-i1"
            />
            <span className="tb04-editorial-t5 TB-F-S">
              <a
                href="https://crucifix.io"
                target="_blank"
                rel="noreferrer noopener"
                className="links-colours"
              >
                Crucifixio, an internet art piece by Chia Amisola.
              </a>
            </span>
          </div>
        </div>
        <div className="tb04-editorial-r3 TB-F-S">
          <div className="tb04-editorial-r3-c1">
            <span className="TB-OCR-M">Making Meaning</span>
            <span className="tb04-editorial-t7 TB-F-M">
              <span>
                ‘A lot of the conversation and discourse [about technology]
                centres on people in the Silicon Valley ... there is this
                narrative of accelerationism and scale and profit and rapid
                growth that is dangerous to people in the Philippines and the
                Global South.’
              </span>
              <br></br>
              <span>— Chia Amisola</span>
            </span>
            <span className="tb04-editorial-t401 TB-F-M">
              Chia’s impetus for founding Developh was their experience growing
              up on the internet, an amorphous and limitless space that, in
              their words, offered them ‘autonomy and agency’. Chia was careful
              to specify that this was liberatory rather than escapist:
              ‘Creating websites was a way to take up territory on the internet.
              The art of being on the internet was an act of world-making.’
              Rather than fleeing from one world, being on the internet was an
              act of extending the self into another world of one’s own
              creation, a way to exist in multiple spaces at once. To Chia, the
              Internet offers a way ‘to distribute power, for anyone to make a
              place of their own’.
            </span>
          </div>
          <div className="tb04-editorial-r3-c2">
            <img
              alt="image"
              src="/touchbase/editorial/e-02.webp"
              loading="lazy"
              className="tb04-editorial-i101"
            />
            <span className="tb04-editorial-t501 TB-F-S">
              Chia at a performative lecture at Gray Area in San Francisco,
              discussing their practice with Developh and as an internet artist.
              Their works focused on an enveloping, loud, and unobstructed
              internet. (Copyright © Chia Amisola, 2023)
            </span>
          </div>
        </div>
        <div className="tb04-editorial-r4 TB-F-S">
          <div className="tb04-editorial-r4-c1">
            <span className="tb04-editorial-t402 TB-F-M">
              <span>
                Developh, too, does not just exist in an abstract, online realm.
                It is a space that is built by and pays homage to the mental and
                physical labour that goes into community-, image-, and
                art-making in the Philippines, one that keeps itself grounded in
                the material world. This is a sharp contrast to where they are
                currently based. ‘I look out of my apartment in San Francisco, a
                place which dictates the trends that today’s technology has
                taken, and then I go home to Manila—these places are so
                different,’ they said. They spoke about looking up at the sky in
                Manila and seeing tangles of wires crackling with electricity,
                of people flocking to internet cafes, where the online is felt,
                laboured, physical. While technology is embodied so viscerally
                in Manila, it is more ephemeral in San Francisco. There, digital
                work is mediated through cloud computing—remote media storage
                and communication apps. The digital world is so pervasive that
                it seems to have disappeared.
              </span>
              <br></br>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <br className="TB-F-M"></br>
              <span>
                But, as Chia reminds us, this invisibility is an illusion. ‘All
                computing is physical computing, it’s all dependent on material
                labour,’ they said, in reference to the Global South’s
                relationship with the digital world and labour. While it often
                seems like big ideas in tech are developed in the Global North,
                it is the workers of the Global South whose work makes this
                digital world possible. In the Philippines alone, roughly 1.6
                million people work in offshore call centres, IT support
                hotlines, and content moderation hubs—all classed under the BPO
                (Business Processing Outsourcing) sector. Even before the
                current glut of AI tools, competition among digital workers in
                developing countries was so intense that many took pay cuts or
                even did assignments for free to retain or attract business. In
                the Philippines today, there are fears that AI is beginning to
                replace human labour.
                <span className="TB-OCR-S tb04-editorial-t3">2</span>
              </span>
              <br></br>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <br className="TB-F-M"></br>
              <span>
                Silicon Valley sees none of these ramifications. Yet this has
                not stopped digital workers in the Global South from asserting
                their visibility. For instance, the Philippines’ BPO Industry
                Employees’ Network (BIEN) is dedicated to safeguarding workers’
                rights and welfare through advocating for job security, safe
                conditions, and appropriate pay.
                <span className="TB-OCR-S tb04-editorial-t3">3</span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
                Although the BPO sector as a whole is stridently anti-union,
                organising efforts such as BIEN’s demonstrate that digital
                workers in the Global South are increasingly unwilling to stay
                silenced and unseen.
              </span>
            </span>
          </div>
          <div className="tb04-editorial-r4-c2">
            <img
              alt="image"
              src="/touchbase/editorial/e-03.webp"
              loading="lazy"
              className="tb04-editorial-i102"
            />
            <span className="tb04-editorial-t502 TB-F-S">
              <a
                href="https://gamechatshop.com"
                target="_blank"
                rel="noreferrer noopener"
                className="links-colours"
              >
                GAMECHATSHOP.com by Chia Amisola is a net art piece about the
                end of an MMORPG’s server, mediated across multiple windows—an
                internet café, chat, and the game itself.
              </a>
            </span>
          </div>
        </div>
        <div className="tb04-editorial-r5 TB-F-S">
          <img
            alt="image"
            src="/touchbase/editorial/e-04.webp"
            loading="lazy"
            className="tb04-editorial-i103"
          />
          <span className="tb04-editorial-t503 TB-F-S">
            <a
              href="https://www.are.na/chia/internet-cafes"
              target="_blank"
              rel="noreferrer noopener"
              className="links-colours"
            >
              Filipino internet cafes collected by Chia Amisola on Are.na.
            </a>
          </span>
        </div>
        <div className="tb04-editorial-r6 TB-F-S">
          <div className="tb04-editorial-r3-c11">
            <span className="tb04-editorial-t61 TB-OCR-M">
              The Resonance of Ritual
            </span>
            <span className="tb04-editorial-t71 TB-F-M">
              <span>
                ‘Faith is one of the strongest drivers of being Filipino to me,
                of being a technologist. Faith in technology is basically faith
                in people. Making online is the biggest thing I believe in.’
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <br className="TB-F-M"></br>
              <span className="TB-F-M">— Chia Amisola</span>
            </span>
            <span className="tb04-editorial-t403 TB-F-M">
              <span>
                Movements like BIEN continue the long thread of mass action in
                the Philippines. In his seminal work{" "}
                <i>Pasyon and the Revolution</i>, Filipino historian Reynaldo
                Ileto interprets popular movements in Filipino history
                (1840-1910) through the lens of the <i>pasyon</i>, an epic poem
                performed communally during Holy Week.
                <span className="TB-OCR-S tb04-editorial-t3">4</span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
                Rather than relying on accounts from the colonial authorities
                and political elite, Ileto attempts to tell Filipino history
                from below. He argues that the <i>pasyon</i> resonated deeply
                with the agricultural masses of the Philippines. They saw their
                modest circumstances mirrored in the story of Christ’s humble
                origins: His birth in a stable and childhood as a carpenter’s
                son. Crucially, the story of the <i>pasyon</i> does not end with
                Christ’s cruxifiction, but his resurrection. Ileto argues that
                this was key in transforming the <i>pasyon</i> from simply ‘a
                tool of pacification into a tool of revolution’: the story of
                the <i>pasyon</i> does not end in death. Rather, death is
                overcome.
                <span className="TB-OCR-S tb04-editorial-t3">5</span>
              </span>
              <br></br>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <br className="TB-F-M"></br>
              <span>
                To Ileto, the performances of the <i>pasyon</i> fostered a sense
                of ‘collective consciousness’ in folk communities. The{" "}
                <i>pasyon</i> was spoken and sung, seen and heard, felt and
                acted; this physicality imbued a metaphysical Bible story with
                immediacy and revolutionary potential. The social process of the
                ritual performance turned thought into gesture, shaping a
                collective worldview that in turn prompted mass action.
                <span className="TB-OCR-S tb04-editorial-t3">6</span>
              </span>
            </span>
            <img
              alt="image"
              src="/touchbase/editorial/e-05.webp"
              loading="lazy"
              className="tb04-editorial-i104"
            />
            <span className="tb04-editorial-t504 TB-F-S">
              <a
                href="https://alli.love/bisita/index.html"
                target="_blank"
                rel="noreferrer noopener"
                className="links-colours"
              >
                Bisita, a net art piece by Chia Amisola recollecting the Holy
                Week ritual of Visita Iglesia, traversing through stations of
                the cross and churches through Google Streetview screenshots.
              </a>
            </span>
            <span className="tb04-editorial-text28 TB-F-M">
              Could technological world-making replicate the contours of ritual?
              The highlight of the <i>pasyon</i> was its communal
              performativity. The power of ritual lies in coming together. Chia,
              who was raised Catholic, referred to the deeply material elements
              and liturgical lushness of Catholic ritual as a touchstone of
              their faith. This sense of coming together is also something they
              feel characterises their devotion to the Internet: ‘Faith and
              technology are the aggregation of the work of millions … if there
              is a new religion then technology must be one form of it, where we
              trust so much in this process to carry humans forward, expand
              human potential.’
            </span>
          </div>
        </div>
        <div className="tb04-editorial-r7 TB-F-S">
          <div className="tb04-editorial-r3-c12">
            <span className="tb04-editorial-t62 TB-OCR-M">
              The Archipelago Online
            </span>
            <span className="tb04-editorial-t72 TB-F-M">
              <span>
                ‘The nature of the Internet, of being Filipino, is acknowledging
                the plausibility and validity of multiple selves and worlds, of
                ways of being.’
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <br className="TB-F-M"></br>
              <span className="TB-F-M">— Chia Amisola</span>
            </span>
            <span className="tb04-editorial-t404 TB-F-M">
              <span>
                But how exactly has faith and technology coalesced through
                communal effort in the Philippines? Activists and artists
                flocked online in the early days of the Internet; Chia described
                this as the Filipino Internet’s ‘militant beginnings’. One
                example is Arkibong Bayan, a Filipino’s people archive started
                by the activist Ramon Ramirez. A GeoCities site formed in 1998,
                Arkibong Bayan was a repository in which Ramirez documented a
                variety of material, including photographs of protests and their
                accompanying paraphernalia for the People’s Movement in the
                Philippines.
                <span className="TB-OCR-S tb04-editorial-t3">7</span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
                When physical gatherings were actively monitored, and public
                speech silenced, the Internet offered a gathering site for
                people to keep their voices and histories alive. To keep such
                online spaces and records alive is to keep the faith, to tend to
                a shared belief that a better world can and will come into
                being.
              </span>
              <br className="TB-F-M"></br>
              <br className="TB-F-M"></br>
              <span>
                However, these online spaces are now at risk, taken down, or
                potentially fading away with the centralisation of the internet
                that has come with the prominence of platforms like X and
                Facebook. ‘We’ve lost our histories, and we’ve lost the means to
                gather again,” said Chia. Developh’s
              </span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
              <a
                href="https://martiallawindex.com/"
                target="_blank"
                rel="noreferrer noopener"
                className="links-colours"
              >
                Martial Law Index
              </a>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
              <span>
                resists this loss by bringing community and folk histories
                online and into conversation with other sources.
                <span className="TB-OCR-S tb04-editorial-t3">8</span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
                This index does not just contain news, academic articles, and
                government documents on the period of the Marcos dictatorship,
                but also film, literature and art. An example is Amisola&apos;s
                piece, Ang Bantayog, made for the Bantayog ng mga Bayani
                Foundation.
              </span>
            </span>
          </div>
          <div className="tb04-editorial-r3-c21">
            <img
              alt="image"
              src="/touchbase/editorial/e-06.gif"
              loading="lazy"
              className="tb04-editorial-i105"
            />
            <span className="tb04-editorial-t505 TB-F-S">
              <a
                href="https://ang-bantayog.com"
                target="_blank"
                rel="noreferrer noopener"
                className="links-colours"
              >
                Ang Bantayog (The Monument), Chia Amisola&apos;s
                &apos;non-memorial&apos; internet art piece where visitors may
                light a candle to reveal the name of a martial law victim. When
                the user&apos;s internet history is erased, all the candles are
                blown out, analogous to the loss and erasure of memory.
              </a>
            </span>
          </div>
        </div>
        <div className="tb04-editorial-r8 TB-F-S">
          <div className="tb04-editorial-r3-c13">
            <span className="tb04-editorial-t405 TB-F-M">
              Going beyond databases that fragment factual accounts, personal
              and artistic interventions interrogate the supposed ‘neutrality’
              of state archives by centering intimate folk narratives. Instead
              of the archive as a tool of empire, it becomes a way for people to
              reclaim their memories. Developh&apos;s efforts like their
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
              <a
                href="https://www.youtube.com/watch?v=GkkSTiizGbo"
                target="_blank"
                rel="noreferrer noopener"
                className="links-colours"
              >
                Sheet Sites workshop
              </a>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
              —which teaches participants how to create personal and community
              archives using spreadsheets—are a call for all of us to step into
              our roles as archivists. Beyond institutions is the possibility of
              distributed, participatory, multi-modal (re)tellings of history.
              <span className="TB-OCR-S tb04-editorial-t3">9</span>
            </span>
          </div>
          <div className="tb04-editorial-r3-c22">
            <img
              alt="image"
              src="/touchbase/editorial/e-07.webp"
              loading="lazy"
              className="tb04-editorial-i106"
            />
            <span className="tb04-editorial-t506">
              <a
                href="https://martiallawindex.com"
                target="_blank"
                rel="noreferrer noopener"
                className="links-colours"
              >
                Martial Law Index, one of Developh&apos;s projects re-presenting
                folk and community archives built by Chia Amisola in response to
                the government takedown of martial law records.
              </a>
            </span>
          </div>
        </div>
        <div className="tb04-editorial-r9 TB-F-S">
          <div className="tb04-editorial-r3-c14">
            <span className="tb04-editorial-t406 TB-F-M">
              Developh’s
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
              <a
                href="https://philippineinternetarchive.com/"
                target="_blank"
                rel="noreferrer noopener"
                className="links-colours"
              >
                Philippine Internet Archive
              </a>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
              is another project that enables participation in historical
              reconstruction. It conducts research on internet history in the
              Philippines collaboratively, inviting the public to submit leads
              for what they wish to see included in the archive. At a later
              juncture, it hopes to teach folk archival and digital justice and
              preservation, all of which would allow everyone to become a
              technologist, or what Chia calls an ‘activator of history’.
            </span>
          </div>
          <div className="tb04-editorial-r3-c23">
            <img
              alt="image"
              src="/touchbase/editorial/e-08.webp"
              loading="lazy"
              className="tb04-editorial-i107"
            />
            <span className="tb04-editorial-t507">
              <a
                href="https://web.archive.org/web/20050924174620/http://www.arkibongbayan.org/"
                target="_blank"
                rel="noreferrer noopener"
                className="links-colours"
              >
                WayBack Machine snapshot of Arkibong Bayan, captured September
                23, 2005.
              </a>
            </span>
          </div>
        </div>
        <div className="tb04-editorial-r10 TB-F-S">
          <div className="tb04-editorial-r3-c15">
            <span className="tb04-editorial-t407 TB-F-M">
              One of the archive’s ongoing projects is KAKAKOMPYUTER MO YAN!, an
              exhibition and publication series highlighting Filipino internet
              counter-narratives and networking cultures. Tied to Chia&apos;s
              personal practice centred around ‘self preservation as communal
              preservation’, each piece draws awareness to the environmental
              conditions of the internet, highlighting that it is a place we can
              make and remake. These works are transient, intended to exist as
              provocations and gathering sites, not artifacts or objects. They
              are inhabited in various ways, unique for their emergent
              conditions, an understanding that Chia emphasises in their
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
              <a
                href="https://chias.website/manifesto"
                target="_blank"
                rel="noreferrer noopener"
                className="links-colours"
              >
                (web)site-specific art manifesto
              </a>
              . Drawing out alternative narratives of labour, devotion,
              exploitation, and piracy, they form archipelagos of their own.
            </span>
          </div>
          <div className="tb04-editorial-r3-c24">
            <img
              alt="image"
              src="/touchbase/editorial/e-09.webp"
              loading="lazy"
              className="tb04-editorial-i108"
            />
            <span className="tb04-editorial-t508 TB-F-S">
              <a
                href="https://kakakompyutermoyan.com"
                target="_blank"
                rel="noreferrer noopener"
                className="links-colours"
              >
                KAKAKOMPYUTER MO YAN&apos;s project logo, made out of flickering
                HTML checkboxes.
              </a>
            </span>
          </div>
        </div>
        <div>
          <div className="tb04-editorial-r3-c25">
            <img
              alt="image"
              src="/touchbase/editorial/e-10.webp"
              loading="lazy"
              className="tb04-editorial-i109"
            />
            <span className="tb04-editorial-t509 TB-F-S">
              &apos;Cybersquatting&apos;, Chia Amisola and Developh&apos;s
              networked, archipelagic internet art piece that distributes
              Filipino homes across 100+ homepages, prompting us to realize that
              all digital space is mapped atop of real space.
            </span>
          </div>
          <div className="tb04-editorial-r3-c16">
            <span className="tb04-editorial-t408 TB-F-M">
              The answer to how to construct a history of the archipelago can
              thus be found in its geography. Each island its own environment,
              yet bridged by a multiplicity of narratives that are fluid and
              ever shifting, made and remade again. Developh’s projects prompt
              us into thinking about how communal practice can manifest through
              translocal technology. No story belonging to a single person, but
              is instead told and retold by multitudes, echoing through history.
            </span>
          </div>
        </div>
        <div className="tb04-editorial-r12 TB-F-S">
          <span className="tb04-editorial-t409 TB-OCR-M">Notes</span>
          <div className="tb04-editorial-r11 TB-F-S">
            <span className="tb04-editorial-text36 TB-OCR-S">
              1
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <a
              href="https://karib.no/articles/10.16993/karib.82"
              target="_blank"
              rel="noreferrer noopener"
              className="tb04-editorial-link TB-F-S"
            >
              Michael Wiedorn, "On the Unfolding of Édouard Glissant’s
              Archipelagic Thought,"{" "}
              <i>Karib – Nordic Journal for Caribbean Studies</i> 6, no. 1
              (2021), 1-7.
            </a>
          </div>
          <div className="tb04-editorial-r21">
            <span className="tb04-editorial-text37 TB-OCR-S">
              2
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <a
              href="https://restofworld.org/2023/ai-revolution-outsourced-workers/"
              target="_blank"
              rel="noreferrer noopener"
              className="tb04-editorial-link1 TB-F-S"
            >
              Andrew Dreck, "The workers at the frontlines of the AI
              revolution," <i>rest of world</i>, 11 July 2023, restofworld.org.
            </a>
          </div>
          <div className="tb04-editorial-r31">
            <span className="tb04-editorial-text38 TB-OCR-S">
              3
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <a
              href="https://bienphilippines.wordpress.com/"
              target="_blank"
              rel="noreferrer noopener"
              className="tb04-editorial-link2 TB-F-S"
            >
              <i>BPO Industry Employees Network</i>,
              bienphilippines.wordpress.com.
            </a>
          </div>
          <div className="tb04-editorial-r41">
            <span className="tb04-editorial-text39 TB-OCR-S">
              4
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="tb04-editorial-text40 TB-F-S">
              Holy Week consists of the seven days leading up to Easter, and is
              a key feature of the Catholic liturgical calendar. Reynaldo C.
              Ileto,{" "}
              <i>
                Pasyon and the Revolution: Popular Movements in the Philippines,
                1840-1910
              </i>{" "}
              (Manila: Ateneo de Manila University Press, 1979).
            </span>
          </div>
          <div className="tb04-editorial-r51">
            <span className="tb04-editorial-text41 TB-OCR-S">
              5
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="tb04-editorial-text42 TB-F-S">
              For an explication of the sociological theories undergirding
              Ileto’s work, and a call for an analysis of the pasyon and mass
              movements that go beyond these frameworks, see Antonio M. La Viña,
              "Between Structures and People: Some Thoughts on the Sociological
              Underpinnings of Ileto’s 'Pasyon and Revolution',"{" "}
              <i>Philippine Sociological Review</i> 31, no. 3 &amp; 4 (1998),
              85-92.
            </span>
          </div>
          <div className="tb04-editorial-r61">
            <span className="tb04-editorial-text43 TB-OCR-S">
              6
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <a
              href="https://karib.no/articles/10.16993/karib.82"
              target="_blank"
              rel="noreferrer noopener"
              className="tb04-editorial-link3 TB-F-S"
            >
              Ibid., 88.
            </a>
          </div>
          <div className="tb04-editorial-r71">
            <span className="tb04-editorial-text44 TB-OCR-S">
              7
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <a
              href="https://origins.osu.edu/milestones/people-power-revolution-philippines-1986?language_content_entity=en"
              target="_blank"
              rel="noreferrer noopener"
              className="tb04-editorial-link4 TB-F-S"
            >
              <span>
                The People Power Revolution was a mass protest that took place
                from 22 to 25 February 1986. It was a response to the snap
                election called by Ferdinand Marcos, who was at that time the
                President of the Philippines. While most of the Filipino left
                refused to legitimise the Marcos regime by participating in the
                elections, the rest of the opposition gathered around the
                candidate Corazan (Cory) Aquino. Marcos, however, claimed that
                he won the elections, leading to the protest that would
                eventually be known as the People Power Revolution. Marcos would
                eventually be forced to abdicate his power and leave the
                Philippines.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                Mark John Sanchez, "The People Power Revolution, Philippines
                1986," <i>Origins: Cultural Events in Historical Perspective</i>
                , February 2021, origins.osu.edu.
              </span>
            </a>
          </div>
          <div className="tb04-editorial-r81">
            <span className="tb04-editorial-text47 TB-OCR-S">
              8
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="tb04-editorial-text48 TB-F-S">
              The Philippines was placed under Martial Law by then President
              Ferdinand Marcos in 1972. Put in place to counter a supposed
              communist threat in the Philippines, the period of Martial Law
              lasted until 1981—although Marcos remained in power until 1986. At
              least 3,000 extrajudicial killings occurred during this period,
              with incidents of incarceration and documented torture reaching
              close to 100,000.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </div>
          <div className="tb04-editorial-r91">
            <span className="tb04-editorial-text49 TB-OCR-S">
              9
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="tb04-editorial-text50 TB-F-S">
              There has been a longstanding debate on the question of history
              versus memory, and how that translates into archival practice.
              History has often been seen as a more intellectual and rigorous
              exercise in comparison to memory—which has traditionally been
              thought of as more emotional and instinct-driven. This, of course,
              diminishes the importance of both memory and emotion in historical
              reconstruction, while placing the historical discipline beyond
              criticism. Here, archivist Mark Greene discusses the false
              dichotomy of memory-history and explains why both hold equal
              weight—and might face equal criticism—when it comes to historical
              reconstruction. Mark A. Greene, "The Messy Business of
              Remembering: History, Memory, and Archives,"{" "}
              <i>Archival Issues</i> 28, no. 2 (2003 &amp; 2004), 95-104.
            </span>
          </div>
        </div>
      </div>
      <div className="tb04-editorial-footer-container">
        <Footer rootClassName="footer-root-class-name35"></Footer>
      </div>
    </div>
  );
};

export default TB04Editorial;
