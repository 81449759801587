import React from 'react'

import PropTypes from 'prop-types'

import './tb-program-item.css'

const TBProgramItem = (props) => {
  return (
    <div className={`tb-program-item-container ${props.rootClassName} `}>
      <span className="tb-program-item-programtitle TB-F-M">
        {props.progTitle}
      </span>
      <img
        alt="image"
        src={props.progImgSrc}
        className="tb-program-item-programimg"
      />
      <span className="tb-program-item-programno TB-F-L">{props.progNo}</span>
    </div>
  )
}

TBProgramItem.defaultProps = {
  progTitle: 'Title',
  rootClassName: '',
  progImgSrc: 'https://play.teleporthq.io/static/svg/default-img.svg',
  progNo: '00',
}

TBProgramItem.propTypes = {
  progTitle: PropTypes.string,
  rootClassName: PropTypes.string,
  progImgSrc: PropTypes.string,
  progNo: PropTypes.string,
}

export default TBProgramItem
