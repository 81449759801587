import React, { useState } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import "./tb-navbar.css";

const TBNavbar = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className={`tb-navbar-navbar ${props.rootClassName} `}>
      <Link to="/" className="tb-navbar-navlink">
        <img src="/feelers/feelerslogo.png" className="tb-navbar-logo" />
      </Link>
      <div className="tb-navbar-links-container">
        <Link to="/tb02-intro" className="tb-navbar-link TB-OCR-S">
          Introduction
        </Link>
        <Link to="/tb03-programs" className="tb-navbar-link1 TB-OCR-S">
          Programs
        </Link>
        <Link to="/tb04-editorial" className="tb-navbar-link2 TB-OCR-S">
          Editorial
        </Link>
        <Link to="/tb05-credits" className="tb-navbar-navlink1 TB-OCR-S">
          Credits
        </Link>
      </div>
    </div>
  );
};

TBNavbar.defaultProps = {
  rootClassName: "",
};

TBNavbar.propTypes = {
  rootClassName: PropTypes.string,
};

export default TBNavbar;
