import React from 'react'

import { Helmet } from 'react-helmet'

import './voices-in-a-room.css'

const VoicesInARoom = (props) => {
  return (
    <div className="voices-in-a-room-container">
      <Helmet>
        <title>voices-in-a-room - Feelers 2024</title>
        <meta property="og:title" content="voices-in-a-room - Feelers 2024" />
      </Helmet>
    </div>
  )
}

export default VoicesInARoom
