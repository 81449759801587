import React from 'react'

import PropTypes from 'prop-types'

import './item.css'

const Item = (props) => {
  return (
    <div className={`item-item ${props.rootClassName} `}>
      <img src={props.itemImg} className="item-item-img" />
      <span className="item-item-title Alt1-S">{props.itemTitle}</span>
    </div>
  )
}

Item.defaultProps = {
  itemImg: '/work/gd.webp',
  rootClassName: '',
  itemTitle: 'Generative Dreaming',
}

Item.propTypes = {
  itemImg: PropTypes.string,
  rootClassName: PropTypes.string,
  itemTitle: PropTypes.string,
}

export default Item
