import React from "react";

import { Helmet } from "react-helmet";

import TBNavbar from "../components/tb-navbar";
import TBCredit from "../components/tb-credit";
import Footer from "../components/footer";
import "./tb05-credits.css";

const TB05Credits = (props) => {
  return (
    <div className="tb05-credits-container">
      <Helmet>
        <title>TB05-Credits - Feelers 2024</title>
        <meta property="og:title" content="TB05-Credits - Feelers 2024" />
      </Helmet>
      <div className="tb05-credits-bg-container"></div>
      <div className="tb05-credits-navbar-container">
        <TBNavbar rootClassName="tb-navbar-root-class-name3"></TBNavbar>
      </div>
      <div className="tb05-credits-body-container">
        <div className="tb05-credits-r1">
          <span className="tb05-credits-t1 TB-OCR-XXL">Credits</span>
          <img
            alt="image"
            src="/touchbase/b-arrow.webp"
            className="tb05-credits-i0"
          />
        </div>
        <div className="tb05-credits-r2">
          <div className="tb05-credits-profile-slot">
            <TBCredit
              creditBio="About to Ask is a project group. It is driven primarily by questions that probe the overlapping territories of architecture, design and art. The group offers responses to these questions based on its foundations of imagination, experimentation, testing and dialogue. Responses may come in many scales and forms, ranging from sheds, drawings, sculptures, building proposals, signboards, pavilions, and everything in between."
              creditImg="/touchbase/bios/b-01.webp"
              creditName="ABOUT TO ASK"
              rootClassName="tb-credit-root-class-name"
            ></TBCredit>
          </div>
          <div className="tb05-credits-profile-slot1">
            <TBCredit
              creditBio="Bussy Temple is a collective of 6 creatives organising queer raves that center femme, trans, and nonbinary folk. Organically formed 2 years ago through the shared frustration over the lack of non cis-men queer spaces in Singapore, our covert gatherings blossomed to be the first explicitly trans, non-binary &amp; femme focused underground party in Singapore—an act of passive rebellion against the backdrop of a hypercapitalistic and socially vigilant cityscape that often neglects the celebration of conventional gender expressions and alternative lifestyles."
              creditImg="/touchbase/bios/b-02.webp"
              creditName="BUSSY TEMPLE"
              rootClassName="tb-credit-root-class-name1"
            ></TBCredit>
          </div>
          <div className="tb05-credits-profile-slot2">
            <TBCredit
              creditBio="Chia Amisola is an internet &amp; ambient artist devoted to the internet’s loss, love, labor, and liberation. Their (web)site-specific practice explores the territory, infrastructure, and intimacies of our environments, seen and unseen. They steward Developh and the Philippine Internet Archive."
              creditImg="/touchbase/bios/b-03.webp"
              creditName="CHIA AMISOLA"
              rootClassName="tb-credit-root-class-name2"
            ></TBCredit>
          </div>
          <div className="tb05-credits-profile-slot3">
            <TBCredit
              creditBio="Chew Shaw En is a performance maker, performer and dance artist. Her continually emerging practice stretches across mediums such as the body, material, image and movement. In her work, she has been drawn to uncovering the kinetic foundations of contemporary life, and also embeddedness as a practice. Image Credit: Mark Benedict Cheong"
              creditImg="/touchbase/bios/b-04.webp"
              creditName="CHEW SHAW EN"
              rootClassName="tb-credit-root-class-name3"
            ></TBCredit>
          </div>
        </div>
        <div className="tb05-credits-r3">
          <div className="tb05-credits-profile-slot4">
            <TBCredit
              creditBio="SOA guides and presents creative expeditions.  SOA promotes creative freedom from self-judgment.  SOA is dedicated to creating experiences that allow one to connect with themselves and others.  SOA celebrates failure — The end is inevitable. The primary goal is to learn how to fail."
              creditImg="/touchbase/bios/b-05.webp"
              creditName="SCHOOL OF ALTRUISM"
              rootClassName="tb-credit-root-class-name4"
            ></TBCredit>
          </div>
          <div className="tb05-credits-profile-slot5">
            <TBCredit
              creditBio="Sharmini Aphrodite was born in Kota Kinabalu and raised between Johor Bahru and Singapore. Her short fiction and essays on art, literature, and history can be found online and in print."
              creditImg="/touchbase/bios/b-06.webp"
              creditName="SHARMINI APHRODITE"
              rootClassName="tb-credit-root-class-name5"
            ></TBCredit>
          </div>
          <div className="tb05-credits-profile-slot6">
            <TBCredit
              creditBio="The Slow Press is an independent food magazine fixated on the rich stories behind what we eat.   Through longform journalism, design, illustration, photography, and videography, the magazine seeks to bring Singapore’s vibrant food heritage to the foreground in an introspective, yet quirky and fun way. We want to shed light on the ways of looking at food to help you truly appreciate the goodness that goes into your stomach."
              creditImg="/touchbase/bios/b-07.webp"
              creditName="SLOW PRESS"
              rootClassName="tb-credit-root-class-name6"
            ></TBCredit>
          </div>
          <div className="tb05-credits-profile-slot7"></div>
        </div>
      </div>
      <div className="tb05-credits-footer-container">
        <Footer rootClassName="footer-root-class-name36"></Footer>
      </div>
    </div>
  );
};

export default TB05Credits;
