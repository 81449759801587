import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./navbar.css";

const Navbar = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className="navbar-navbar">
      <Link to="/" className="navbar-navlink">
        <img src="/feelers/feelerslogo.png" className="navbar-logo" />
      </Link>
      <div className="navbar-links-container">
        <Link to="/about" className="navbar-link Navbar-Link">
          About
        </Link>
        <Link to="/education" className="navbar-link1 Navbar-Link">
          Education
        </Link>
        <Link to="/archive" className="navbar-link2 Navbar-Link">
          Archive
        </Link>
        <Link to="/contact" className="navbar-link3 Navbar-Link">
          Contact
        </Link>
        <a
          href="https://feelers.bigcartel.com"
          target="_blank"
          rel="noreferrer noopener"
          className="navbar-link4 Navbar-Link"
        >
          Shop
        </a>
      </div>
    </div>
  );
};

export default Navbar;
