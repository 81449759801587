import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import "./tb01-main.css";

const TB01Main = (props) => {
  return (
    <div className="tb01-main-container">
      <Helmet>
        <title>Feelers 2024</title>
        <meta property="og:title" content="Feelers 2024" />
      </Helmet>
      <div className="tb01-main-bg-container">
        <img src="/touchbase/tb_logo_web.webp" className="tb01-main-image" />
      </div>
      <div className="tb01-main-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="tb01-main-body-container">
        <div className="tb01-main-r1">
          <Link to="/tb02-intro" className="tb01-main-t1 TB-OCR-XXL">
            Touch Base
          </Link>
          <div className="tb01-main-r1-c2">
            <span className="tb01-main-t2 TB-OCR-M">Seasons 2024</span>
            <span className="tb01-main-t3 TB-F-S">
              Touch Base is Feelers’ very first Season — an annual series of
              interdisciplinary programmes that flow along circuits and fault
              lines between art and technology. As change continues to
              accelerate beyond perception, we insist on slowing down. We begin
              by going back to basics, going back to the body.
            </span>
            <Link to="/tb02-intro" className="tb01-main-t4 TB-OCR-S">
              ENTER
            </Link>
          </div>
        </div>
        <div className="tb01-main-r3">
          <div className="tb01-main-r3-r1">
            <span className="tb01-main-t41 TB-OCR-XL">
              <span>Program</span>
              <br></br>
              <span>Highlights</span>
            </span>
            <img
              alt="image"
              src="/touchbase/b-arrow.webp"
              className="tb01-main-i1"
            />
          </div>
          <div className="tb01-main-r3-r2">
            <span className="tb01-main-t5 TB-OCR-L">Speakers</span>
            <img
              alt="image"
              src="/touchbase/g-arrow.webp"
              className="tb01-main-i2"
            />
          </div>
          <div className="tb01-main-r3-r3">
            <span className="tb01-main-t51 TB-OCR-L">Workshops</span>
            <img
              alt="image"
              src="/touchbase/g-arrow.webp"
              className="tb01-main-i21"
            />
          </div>
        </div>
      </div>
      <div className="tb01-main-footer-container">
        <Footer rootClassName="footer-root-class-name31"></Footer>
      </div>
    </div>
  );
};

export default TB01Main;
