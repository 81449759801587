import React from 'react'

import { Helmet } from 'react-helmet'

import './office-hands-edition.css'

const OfficeHandsEdition = (props) => {
  return (
    <div className="office-hands-edition-container">
      <Helmet>
        <title>Office-Hands-Edition - Feelers 2024</title>
        <meta
          property="og:title"
          content="Office-Hands-Edition - Feelers 2024"
        />
      </Helmet>
    </div>
  )
}

export default OfficeHandsEdition
