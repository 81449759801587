import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "./style.css";
import Popup from "./components/popup";
import TB02Intro from "./views/tb02-intro";
import TB03Programs4 from "./views/tb03-programs4";
import TB03Programs from "./views/tb03-programs";
import TB05Credits from "./views/tb05-credits";
import TB03Programs1 from "./views/tb03-programs1";
import TB03Programs5 from "./views/tb03-programs5";
import TB03Programs3 from "./views/tb03-programs3";
import TB04Editorial from "./views/tb04-editorial";
import TB03Programs2 from "./views/tb03-programs2";
import GenerativeDreaming from "./views/generative-dreaming";
import Totapo from "./views/totapo";
import Work from "./views/work";
import SoaiJuly from "./views/soai-july";
import AcrossASmallDistance from "./views/across-a-small-distance";
import GoodAMToYou from "./views/good-am-to-you";
import SoaiMain from "./views/soai-main";
import AnotherWorldIsPossible from "./views/another-world-is-possible";
import Contact from "./views/contact";
import AdvertisementsNowOnline from "./views/advertisements-now-online";
import OfficeHandsEdition from "./views/office-hands-edition";
import Souvenir from "./views/souvenir";
import InteractiveRotaryPhone from "./views/interactive-rotary-phone";
import BuildACaifan from "./views/build-a-caifan";
import VoicesInARoom from "./views/voices-in-a-room";
import PrivacyPolicy from "./views/privacy-policy";
import About from "./views/about";
import KineticBirds from "./views/kinetic-birds";
import TB01Main from "./views/tb01-main";
import YouTube from "./views/you-tube";
import Archive from "./views/archive";
import TalkTouch from "./views/talk-touch";
import StreetlampNo107101 from "./views/streetlamp-no-107101";
import Education from "./views/education";
import Services from "./views/services";
import WritingMachine from "./views/writing-machine";
import Labs from "./views/labs";
import GreenerScreens from "./views/greener-screens";
import SoaiLite from "./views/soai-lite";
import Caterfeelers from "./views/caterfeelers";
import NotFound from "./views/not-found";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={TB02Intro} exact path="/tb02-intro" />
        <Route component={TB03Programs4} exact path="/tb03-programs4" />
        <Route component={TB03Programs} exact path="/tb03-programs" />
        <Route component={TB05Credits} exact path="/tb05-credits" />
        <Route component={TB03Programs1} exact path="/tb03-programs1" />
        <Route component={TB03Programs5} exact path="/tb03-programs5" />
        <Route component={TB03Programs3} exact path="/tb03-programs3" />
        <Route component={TB04Editorial} exact path="/tb04-editorial" />
        <Route component={TB03Programs2} exact path="/tb03-programs2" />
        <Route
          component={GenerativeDreaming}
          exact
          path="/generative-dreaming"
        />
        <Route component={Totapo} exact path="/totapo" />
        <Route component={Work} exact path="/work" />
        <Route component={SoaiJuly} exact path="/soai-july" />
        <Route
          component={AcrossASmallDistance}
          exact
          path="/across-a-small-distance"
        />
        <Route component={GoodAMToYou} exact path="/good-am-to-you" />
        <Route component={SoaiMain} exact path="/soai-main" />
        <Route
          component={AnotherWorldIsPossible}
          exact
          path="/another-world-is-possible"
        />
        <Route component={Contact} exact path="/contact" />
        <Route
          component={AdvertisementsNowOnline}
          exact
          path="/advertisements-now-online"
        />
        <Route
          component={OfficeHandsEdition}
          exact
          path="/office-hands-edition"
        />
        <Route component={Souvenir} exact path="/souvenir" />
        <Route
          component={InteractiveRotaryPhone}
          exact
          path="/interactive-rotary-phone"
        />
        <Route component={BuildACaifan} exact path="/build-a-caifan" />
        <Route component={VoicesInARoom} exact path="/voices-in-a-room" />
        <Route component={PrivacyPolicy} exact path="/privacy-policy" />
        <Route component={About} exact path="/about" />
        <Route component={KineticBirds} exact path="/kinetic-birds" />
        <Route component={TB01Main} exact path="/" />
        <Route component={YouTube} exact path="/you-tube" />
        <Route component={Archive} exact path="/archive" />
        <Route component={TalkTouch} exact path="/talk-touch" />
        <Route
          component={StreetlampNo107101}
          exact
          path="/streetlamp-no-107101"
        />
        <Route component={Education} exact path="/education" />
        <Route component={Services} exact path="/services" />
        <Route component={WritingMachine} exact path="/writing-machine" />
        <Route component={Labs} exact path="/labs" />
        <Route component={GreenerScreens} exact path="/greener-screens" />
        <Route component={SoaiLite} exact path="/soai-lite" />
        <Route component={Caterfeelers} exact path="/caterfeelers" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
        <Popup />
      </Switch>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
