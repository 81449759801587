import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Profile from '../components/profile'
import Footer from '../components/footer'
import './good-am-to-you.css'

const GoodAMToYou = (props) => {
  return (
    <div className="good-am-to-you-container">
      <Helmet>
        <title>Good-AM-to-You - Feelers 2024</title>
        <meta property="og:title" content="Good-AM-to-You - Feelers 2024" />
      </Helmet>
      <div className="good-am-to-you-bg-container">
        <img src="/feelers/bgcircle.png" className="good-am-to-you-image" />
        <img src="/feelers/bgcircle.png" className="good-am-to-you-image1" />
        <img src="/feelers/bgcircle.png" className="good-am-to-you-image2" />
      </div>
      <div className="good-am-to-you-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="good-am-to-you-body-container">
        <div className="good-am-to-you-left-container">
          <h1 className="good-am-to-you-title Title-XXL">Good AM to You</h1>
          <div className="good-am-to-you-img-container">
            <img src="/goodam/ga-1.webp" className="good-am-to-you-image3" />
            <img src="/goodam/ga-2.webp" className="good-am-to-you-image4" />
            <img src="/goodam/ga-3.webp" className="good-am-to-you-image5" />
          </div>
        </div>
        <div className="good-am-to-you-right-container">
          <div className="good-am-to-you-body-container1">
            <div className="good-am-to-you-row1-container">
              <div className="good-am-to-you-col1-container">
                <span className="good-am-to-you-text Body-S">
                  <span>library@orchard</span>
                  <br className="Body-S"></br>
                  <span className="Body-S">20 November 2022</span>
                  <br className="Body-S"></br>
                  <span className="Body-S">1pm – 4pm</span>
                  <br className="Body-S"></br>
                </span>
                <span className="good-am-to-you-text07 Body-S">
                  This programme is part of NLB’s LOLA (RE)CONNECT Series.
                </span>
              </div>
              <div className="good-am-to-you-col2-container">
                <h1 className="good-am-to-you-text08 Body-L">
                  DIY! Good AM Graphic &amp; WhatsApp Sticker Booth
                </h1>
                <span className="good-am-to-you-text09 Body-M">
                  <span>
                    Colloquially-known “Good Morning” WhatsApp images are easily
                    identified by their colourful, saturated stock backgrounds
                    featuring positive daily tidings in mismatched fonts.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    Visit the activity booth to make your own “Good Morning”
                    graphics using a generator created by Singaporean art &amp;
                    tech label Feelers! The first 30 participants will have
                    their generated images made into WhatsApp stickers.
                  </span>
                </span>
                <a
                  href="https://getstickerpack.com/stickers/good-am-2-u"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="good-am-to-you-link Body-M"
                >
                  Get stickers on WhatsApp.
                </a>
                <a
                  href="https://t.me/addstickers/goodAM2u"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="good-am-to-you-link1 Body-M"
                >
                  Get stickers on Telegram.
                </a>
                <a
                  href="https://feelers-ashley.github.io/goodAM/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="good-am-to-you-link2 Body-M"
                >
                  Try the generator online here.
                </a>
              </div>
            </div>
            <div className="good-am-to-you-row2-container">
              <div className="good-am-to-you-col1-container1">
                <span className="good-am-to-you-text14 Body-S">
                  <span>library@orchard</span>
                  <br className="Body-S"></br>
                  <span className="Body-S">(Imagine This)</span>
                  <br className="Body-S"></br>
                  <span className="Body-S">20 November 2022</span>
                  <br className="Body-S"></br>
                  <span className="Body-S">2pm – 3pm</span>
                  <br className="Body-S"></br>
                </span>
                <span className="good-am-to-you-text23 Body-S">
                  This programme is part of NLB’s LOLA (RE)CONNECT Series.
                </span>
              </div>
              <div className="good-am-to-you-col2-container1">
                <h1 className="good-am-to-you-text24 Body-L">
                  Understanding the Design &amp; Appeal of Good Morning Graphics
                </h1>
                <span className="good-am-to-you-text25 Body-M">
                  Join us for a sharing by graphic designers Qing (OuterEdit),
                  Iffah Dahiyah (Fellow), and Izyanti Asa&apos;ari (Fellow) on
                  their favourite “Good Morning” graphics! They will also create
                  “Good Morning” graphics on the spot for critique. Which design
                  will make it out alive?
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="good-am-to-you-row3-container">
              <div className="good-am-to-you-col1-container2">
                <Profile
                  profileImg="/goodam/qing.webp"
                  profileTitle="OuterEdit"
                  profileName="Qing"
                  rootClassName="profile-root-class-name"
                ></Profile>
              </div>
              <div className="good-am-to-you-col2-container2">
                <span className="good-am-to-you-text26 Body-M">
                  Qing from OuterEdit is a graphic designer with an interest in
                  typography, culture and language. She is always intrigued by
                  how people communicate and deeply resonates with how type
                  design can enable written languages and foster communities.
                  She started delving into typography through Chinese type in
                  2015. Qing has experience working with various local studios,
                  spanning from branding to exhibition projects.
                </span>
              </div>
            </div>
            <div className="good-am-to-you-row4-container">
              <div className="good-am-to-you-col1-container3">
                <Profile
                  profileImg="/goodam/iffah.webp"
                  profileTitle="Fellow"
                  profileName="Iffah Dahiyah"
                  rootClassName="profile-root-class-name"
                ></Profile>
                <Profile
                  profileImg="/goodam/izz.webp"
                  profileTitle="Fellow"
                  profileName="Izyanti Asa'ari"
                  rootClassName="profile-root-class-name"
                ></Profile>
              </div>
              <div className="good-am-to-you-col2-container3">
                <span className="good-am-to-you-text27 Body-M">
                  Iffah Dahiyah &amp; Izyanti Asa’ari are from Fellow, a design
                  practice that centers its work on art, culture and heritage.
                  By exploring narratives and behavioural rhythms, Fellow hopes
                  to use design as a tool to better understand our relationship
                  as individuals with our environment — be it physical, digital
                  or graphic.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="good-am-to-you-footer-container">
        <Footer rootClassName="footer-root-class-name"></Footer>
      </div>
    </div>
  )
}

export default GoodAMToYou
