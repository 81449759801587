import React from 'react'

import { Helmet } from 'react-helmet'

import './souvenir.css'

const Souvenir = (props) => {
  return (
    <div className="souvenir-container">
      <Helmet>
        <title>Souvenir - Feelers 2024</title>
        <meta property="og:title" content="Souvenir - Feelers 2024" />
      </Helmet>
    </div>
  )
}

export default Souvenir
