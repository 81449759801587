import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './build-a-caifan.css'

const BuildACaifan = (props) => {
  return (
    <div className="build-a-caifan-container">
      <Helmet>
        <title>Build-A-Caifan - Feelers 2024</title>
        <meta property="og:title" content="Build-A-Caifan - Feelers 2024" />
      </Helmet>
      <div className="build-a-caifan-bg-container">
        <img src="/feelers/bgcircle.png" className="build-a-caifan-image" />
        <img src="/feelers/bgcircle.png" className="build-a-caifan-image1" />
        <img src="/feelers/bgcircle.png" className="build-a-caifan-image2" />
      </div>
      <div className="build-a-caifan-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="build-a-caifan-body-container">
        <div className="build-a-caifan-left-container">
          <h1 className="build-a-caifan-title Title-XXL">Build-A-Caifan</h1>
          <div className="build-a-caifan-img-container">
            <div className="build-a-caifan-col2">
              <img
                src="/caifan/caifan-2.webp"
                className="build-a-caifan-image3"
              />
              <img
                src="/caifan/caifan-1.webp"
                className="build-a-caifan-image4"
              />
            </div>
            <img
              src="/caifan/caifan-3.webp"
              className="build-a-caifan-image5"
            />
            <img
              src="/caifan/caifan-4.webp"
              className="build-a-caifan-image6"
            />
          </div>
        </div>
        <div className="build-a-caifan-right-container">
          <div className="build-a-caifan-header-container">
            <h1 className="build-a-caifan-text">🥔🥦🌶️🥚🍆🍗🍖🥬🥩🥒</h1>
          </div>
          <div className="build-a-caifan-body-container1">
            <div className="build-a-caifan-row1-container">
              <div className="build-a-caifan-left-container1"></div>
              <span className="build-a-caifan-text1 Body-M">
                Build-A-Caifan is an imaginary online caifan stall which
                digitally replicates the experience of picking out dishes at a
                stall through clicking on food emojis.
              </span>
            </div>
            <div className="build-a-caifan-row2-container">
              <a
                href="https://www.instagram.com/caipng2.50/"
                target="_blank"
                rel="noreferrer noopener"
                className="build-a-caifan-link Body-S"
              >
                Machine learning model trained on dataset which consists of
                images from @caipng2.50.
              </a>
              <div className="build-a-caifan-col2-container">
                <span className="build-a-caifan-text2 Body-M">
                  The dishes selected are reflected real-time as illustrations
                  on a plate before being digitally &quot;cooked&quot; using
                  machine learning to present a final, realistic-looking plate
                  of piping-hot, ready-to-eat caifan.
                </span>
                <a
                  href="https://feelers-ashley.github.io/caifan/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="build-a-caifan-link1 Body-M"
                >
                  👀 Experience the work here.
                </a>
                <span className="build-a-caifan-text3 Body-M">
                  (Requires iOS 15 and above for Apple devices.)
                </span>
                <div className="build-a-caifan-iframe-container">
                  <div className="build-a-caifan-container1">
                    <div className="build-a-caifan-container2">
                      <Script
                        html={`<iframe width="100%" height="100%" src="https://www.youtube.com/embed/yM80fMxqU3A" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`}
                      ></Script>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="build-a-caifan-footer-container">
        <Footer rootClassName="footer-root-class-name"></Footer>
      </div>
    </div>
  )
}

export default BuildACaifan
