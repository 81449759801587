import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './talk-touch.css'

const TalkTouch = (props) => {
  return (
    <div className="talk-touch-container">
      <Helmet>
        <title>TalkTouch - Feelers 2024</title>
        <meta property="og:title" content="TalkTouch - Feelers 2024" />
      </Helmet>
      <div className="talk-touch-bg-container">
        <img src="/feelers/bgcircle.png" className="talk-touch-image" />
        <img src="/feelers/bgcircle.png" className="talk-touch-image1" />
        <img src="/feelers/bgcircle.png" className="talk-touch-image2" />
      </div>
      <div className="talk-touch-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="talk-touch-body-container">
        <div className="talk-touch-left-container">
          <h1 className="talk-touch-title Title-XXL">TalkTouch</h1>
          <div className="talk-touch-img-container">
            <img src="/talktouch/tt-2.webp" className="talk-touch-image3" />
            <div className="talk-touch-col2">
              <img src="/talktouch/tt-3.webp" className="talk-touch-image4" />
              <img src="/talktouch/tt-4.webp" className="talk-touch-image5" />
            </div>
            <img src="/talktouch/tt-1.webp" className="talk-touch-image6" />
          </div>
        </div>
        <div className="talk-touch-right-container">
          <div className="talk-touch-header-container">
            <h1 className="talk-touch-text Body-L">
              Designing a system which transmits physical touch generatively and
              remotely across distances.
            </h1>
          </div>
          <div className="talk-touch-body-container1">
            <div className="talk-touch-row1-container">
              <div className="talk-touch-left-container1"></div>
              <span className="talk-touch-text01 Body-M">
                <span>
                  TalkTouch aims to enhance the experience of digital
                  communication by providing real-time haptic feedback, that
                  bridges the gap of physical distance between loved ones,
                  friends, and family. With a combination of a web application
                  that communicates with a lightweight haptic backpack, wearers
                  can experience the touch of a loved one even if they are
                  oceans apart.
                </span>
                <br className="Body-M"></br>
              </span>
            </div>
            <div className="talk-touch-row2-container">
              <div className="talk-touch-col1-container">
                <span className="talk-touch-text04 Body-M">
                  <span>Capstone Team</span>
                  <br></br>
                </span>
                <span className="talk-touch-text07 Body-S">
                  <span>Jeremy Surjoputro (EPD)</span>
                  <br></br>
                  <span>Maddula Akshaya (EPD)</span>
                  <br></br>
                  <span>Akshaya Rajesh (EPD)</span>
                  <br></br>
                  <span>Wong Kai-En Matthew Ryan (ISTD)</span>
                  <br></br>
                  <span>Ang Sok Teng Cassie (ISTD)</span>
                  <br></br>
                </span>
                <span className="talk-touch-text18 Body-M">
                  <span>Instructors</span>
                  <br></br>
                </span>
                <span className="talk-touch-text21 Body-S">
                  <span>Nagarajan Raghavan</span>
                  <br></br>
                  <span>Kenny Lu</span>
                  <br></br>
                </span>
                <span className="talk-touch-text26 Body-M">
                  <span>Company Mentor</span>
                  <br></br>
                </span>
                <span className="talk-touch-text29 Body-S">
                  <span>Feelers</span>
                  <br></br>
                </span>
              </div>
              <div className="talk-touch-col2-container">
                <span className="talk-touch-text32 Body-M">
                  The project was a part of Singapore University of Technology
                  and Design (SUTD)&apos;s Capstone Design Programme. The
                  Capstone project is a collaboration between companies and
                  senior-year students. Students of different majors come
                  together to work in teams and contribute their technology and
                  design expertise to solve real-world challenges faced by
                  companies.
                </span>
                <a
                  href="https://capstone2022.sutd.edu.sg/projects/talktouch"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="talk-touch-link Body-M"
                >
                  Find out more about the project here.
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="talk-touch-footer-container">
        <Footer rootClassName="footer-root-class-name"></Footer>
      </div>
    </div>
  )
}

export default TalkTouch
