import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import TBNavbar from "../components/tb-navbar";
import TBProgramItem from "../components/tb-program-item";
import "./tb03-programs4.css";

const TB03Programs4 = (props) => {
  return (
    <div className="tb03-programs4-container">
      <Helmet>
        <title>TB03-Programs4 - Feelers 2024</title>
        <meta property="og:title" content="TB03-Programs4 - Feelers 2024" />
      </Helmet>
      <div className="tb03-programs4-bg-container"></div>
      <div className="tb03-programs4-navbar-container">
        <TBNavbar rootClassName="tb-navbar-root-class-name11"></TBNavbar>
      </div>
      <div className="tb03-programs4-body-container">
        <div className="tb03-programs4-r1">
          <span className="tb03-programs4-t1 TB-OCR-XXL">Programs</span>
          <img
            alt="image"
            src="/touchbase/b-arrow.webp"
            className="tb03-programs4-i1"
          />
        </div>
        <div className="tb03-programs4-r2">
          <TBProgramItem
            progNo="01"
            progTitle="LET ME SHOW YOU WHAT I SAW"
            progImgSrc="/touchbase/programs/p-01.webp"
            rootClassName="tb-program-item-root-class-name49"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs4-r3">
          <TBProgramItem
            progNo="02"
            progTitle="TOUCH_TIME (T_T)"
            progImgSrc="/touchbase/programs/p-02.webp"
            rootClassName="tb-program-item-root-class-name50"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs4-r4">
          <TBProgramItem
            progNo="03"
            progTitle="PERFOR.ML"
            progImgSrc="/touchbase/programs/p-03.webp"
            rootClassName="tb-program-item-root-class-name51"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs4-r5">
          <TBProgramItem
            progNo="04"
            progTitle="RANDOM ACCESS MEMORY"
            rootClassName="tb-program-item-root-class-name52"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs4-r6">
          <TBProgramItem
            progNo="05"
            progTitle="HOW TO GO ON"
            rootClassName="tb-program-item-root-class-name53"
          ></TBProgramItem>
        </div>
      </div>
      <div className="tb03-programs4-pop-container">
        <div className="tb03-programs4-c1">
          <Link to="/tb03-programs" className="tb03-programs4-link TB-F-L">
            X
          </Link>
          <span className="tb03-programs4-text TB-OCR-M">ABOUT</span>
          <span className="tb03-programs4-text01 TB-F-S">
            <span>
              Shoulders tense differently under the weight of a laptop; hands
              learn to accommodate the width of a tablet. We walk, run, dance,
              rest with phones by our sides. We turn our heads toward the glow
              of our screens. We already move with machines.
            </span>
            <br></br>
            <br></br>
            <span>
              But what is the texture of touch that we direct to our devices?
              How would it feel to move like a machine? The interactions we have
              with technology need not leave us cold. This workshop asks us to
              think with the body, opening a space for dialogue and
              understanding to be built through movement. Random Access Memory
              expands the limits of what an embodied encounter with technology
              could look and feel like.
            </span>
            <br></br>
            <br></br>
            <span>
              No movement/dance background is required to attend this workshop.
              There will be contact and full-bodied movement tasks, so dress
              comfortably and be prepared to move. The workshop will run from
              10am – 12pm, and attendees are invited to join us for lunch at a
              nearby hawker centre afterwards.
            </span>
          </span>
          <img
            alt="image"
            src="/touchbase/programs/p-04.webp"
            className="tb03-programs4-image"
          />
        </div>
        <div className="tb03-programs4-c2">
          <Link to="/tb03-programs" className="tb03-programs4-navlink TB-F-L">
            X
          </Link>
          <span className="tb03-programs4-text09 TB-OCR-S">
            <span>11 MAY 2024</span>
            <br></br>
            <span>10AM–12PM</span>
            <br></br>
            <span>DANCE NUCLEUS</span>
            <br></br>
            <span>90 GOODMAN ROADBLK M #02-53</span>
            <br></br>
            <span>SINGAPORE 439053</span>
          </span>
          <span className="tb03-programs4-text19 TB-OCR-XL">
            RANDOM ACCESS MEMORY
          </span>
          <a
            href="https://www.eventbrite.com/e/random-access-memory-tickets-846862918787?aff=oddtdtcreator"
            target="_blank"
            rel="noreferrer noopener"
            className="tb03-programs4-link1 TB-OCR-S"
          >
            GET TICKETS
          </a>
        </div>
      </div>
    </div>
  );
};

export default TB03Programs4;
