import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Item from '../components/item'
import Footer from '../components/footer'
import './work.css'

const Work = (props) => {
  return (
    <div className="work-container">
      <Helmet>
        <title>Work - Feelers 2024</title>
        <meta property="og:title" content="Work - Feelers 2024" />
      </Helmet>
      <div className="work-bg-container">
        <img src="/feelers/bgcircle.png" className="work-image" />
        <img src="/feelers/bgcircle.png" className="work-image1" />
        <img src="/feelers/bgcircle.png" className="work-image2" />
      </div>
      <div className="work-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="work-title-container">
        <h1 className="work-rnd list Title-L">R&amp;D</h1>
        <h1 className="work-projects list Title-L">Projects</h1>
        <h1 className="work-products list Title-L">Products</h1>
        <h1 className="work-education list Title-L">Education</h1>
        <h1 className="work-outreach Title-L list">outreach</h1>
      </div>
      <div className="work-body-container">
        <div className="work-row1-container">
          <div className="list work-col1-container">
            <Link to="/talk-touch">
              <div className="work-talktouch list-item">
                <Item
                  itemImg="/work/talktouch.webp"
                  itemTitle="TalkTouch"
                ></Item>
              </div>
            </Link>
          </div>
          <div className="list work-col2-container">
            <Link to="/interactive-rotary-phone">
              <div className="work-rotary-phone list-item">
                <Item
                  itemImg="/work/ttnw.webp"
                  itemTitle="Interactive Rotary Phone"
                ></Item>
              </div>
            </Link>
          </div>
          <div className="work-col345-container">
            <div className="work-row1-container1">
              <div className="work-col34-container list"></div>
              <div className="work-col5-container list">
                <Link to="/writing-machine">
                  <div className="work-writing-machine list-item">
                    <Item
                      itemImg="/work/writemachine1.webp"
                      itemTitle="Writing Machine"
                    ></Item>
                  </div>
                </Link>
              </div>
            </div>
            <div className="work-row2-container">
              <div className="work-col34-container1 list">
                <Link to="/writing-machine">
                  <div className="work-writing-machine1 list-item">
                    <Item
                      itemImg="/work/writemachine1.webp"
                      itemTitle="Writing Machine"
                    ></Item>
                  </div>
                </Link>
              </div>
              <div className="work-col5-container1 list"></div>
            </div>
            <div className="work-row3-container">
              <div className="work-col3-container list"></div>
              <div className="work-col4-container list">
                <Link to="/kinetic-birds">
                  <div className="work-kinetic-birds list-item">
                    <Item
                      itemImg="/work/kineticbirds.webp"
                      itemTitle="Kinetic Birds"
                    ></Item>
                  </div>
                </Link>
              </div>
              <div className="work-col5-container2 list">
                <Link to="/generative-dreaming">
                  <div className="work-generative-dreaming list-item">
                    <Item
                      itemImg="/work/gd.webp"
                      itemTitle="Generative Dreaming"
                      rootClassName="item-root-class-name2"
                    ></Item>
                  </div>
                </Link>
                <Link to="/good-am-to-you">
                  <div className="work-good-am list-item">
                    <Item
                      itemImg="/work/goodam.webp"
                      itemTitle="Good AM to You"
                    ></Item>
                  </div>
                </Link>
              </div>
            </div>
            <Link to="/greener-screens" className="work-navlink07">
              <div className="work-greener-screens list-item">
                <Item
                  itemImg="/work/greenerscreens.webp"
                  itemTitle="Greener Screens"
                ></Item>
              </div>
            </Link>
          </div>
        </div>
        <div className="work-row2-container1">
          <div className="list work-col1-container1">
            <Link to="/labs">
              <div className="work-labs list-item">
                <Item
                  itemImg="/work/officehandsedition.webp"
                  itemTitle="Labs"
                ></Item>
              </div>
            </Link>
          </div>
          <div className="list work-col2-container1">
            <Link to="/streetlamp-no-107101">
              <div className="work-streetlamp list-item">
                <Item
                  itemImg="/work/streetlamp.webp"
                  itemTitle="Streetlamp No. 107101"
                ></Item>
              </div>
            </Link>
            <Link to="/build-a-caifan">
              <div className="work-caifan list-item">
                <Item
                  itemImg="/work/caifan.webp"
                  itemTitle="Build-A-Caifan"
                ></Item>
              </div>
            </Link>
          </div>
          <div className="list work-col3-container1">
            <Link to="/caterfeelers">
              <div className="work-caterfeelers list-item">
                <Item
                  itemImg="/work/caterfeelers.webp"
                  itemTitle="caterfeelers"
                ></Item>
              </div>
            </Link>
          </div>
          <div className="work-col45-container">
            <div className="work-row1-container2">
              <div className="work-col4-container1 list"></div>
              <div className="work-col5-container3 list">
                <Link to="/another-world-is-possible">
                  <div className="list-item">
                    <Item
                      itemImg="/work/anotherworld.webp"
                      itemTitle="Another World is Possible"
                    ></Item>
                  </div>
                </Link>
              </div>
            </div>
            <Link to="/you-tube" className="work-navlink13">
              <div className="work-youtube list-item">
                <Item itemImg="/work/youtube.webp" itemTitle="YouTube"></Item>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="work-footer-container">
        <Footer rootClassName="footer-root-class-name"></Footer>
      </div>
    </div>
  )
}

export default Work
