import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import "./soai-main.css";

const SoaiMain = (props) => {
  return (
    <div className="soai-main-container">
      <Helmet>
        <title>Soai-Main - Feelers 2024</title>
        <meta property="og:title" content="Soai-Main - Feelers 2024" />
      </Helmet>
      <div className="soai-main-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="soai-main-body">
        <div className="soai-main-r1">
          <div className="soai-main-r1-c1">
            <img src="/soai/wifi%20flower%403x-300w.webp" />
          </div>
          <div className="soai-main-r1-c2">
            <span className="soai-main-t1 SoAI-M">
              <span className="SoAI-M soai-main-text">
                The
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className="SoAI-MB">School Of Alternate Internets</span>
              <span className="SoAI-M soai-main-text02">
                {" "}
                is a new educational programme by Feelers for critical thinking,
                doing, and undoing around technology.
              </span>
            </span>
          </div>
          <div className="soai-main-r1-c3">
            <img
              src="/soai/yellow%20spark%403x-200w.webp"
              className="soai-main-i2"
            />
          </div>
        </div>
        <div className="soai-main-r2">
          <Link to="/soai-july" className="soai-main-navlink">
            <div className="soai-main-r2-c1">
              <span className="soai-main-t2 SoAI-M">
                <span className="SoAI-MB">School of Alternate Internets</span>
                <span className="SoAI-M"> Jul 23</span>
                <br className="SoAI-M"></br>
                <span className="SoAI-M">
                  School&apos;s out! Find out more.
                </span>
                <br className="SoAI-M"></br>
              </span>
              <img src="/soai/sl-1-1500w.webp" className="soai-main-i3" />
            </div>
          </Link>
          <Link to="/soai-lite" className="soai-main-navlink1">
            <div className="soai-main-r2-c2">
              <span className="soai-main-t3">
                <span className="SoAI-MB">
                  School of Alternate Internets Lite
                </span>
                <span className="SoAI-M"> Dec 23</span>
                <br className="SoAI-M"></br>
                <span className="soai-main-text11 SoAI-M">Find out more.</span>
                <br className="SoAI-M"></br>
              </span>
              <img src="/soai/sl-2-1500w.webp" className="soai-main-i4" />
            </div>
          </Link>
        </div>
      </div>
      <div className="soai-main-footer-container">
        <Footer rootClassName="footer-root-class-name25"></Footer>
      </div>
    </div>
  );
};

export default SoaiMain;
