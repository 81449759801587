import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import TBNavbar from "../components/tb-navbar";
import TBProgramItem from "../components/tb-program-item";
import "./tb03-programs5.css";

const TB03Programs5 = (props) => {
  return (
    <div className="tb03-programs5-container">
      <Helmet>
        <title>TB03-Programs5 - Feelers 2024</title>
        <meta property="og:title" content="TB03-Programs5 - Feelers 2024" />
      </Helmet>
      <div className="tb03-programs5-bg-container"></div>
      <div className="tb03-programs5-navbar-container">
        <TBNavbar rootClassName="tb-navbar-root-class-name12"></TBNavbar>
      </div>
      <div className="tb03-programs5-body-container">
        <div className="tb03-programs5-r1">
          <span className="tb03-programs5-t1 TB-OCR-XXL">Programs</span>
          <img
            alt="image"
            src="/touchbase/b-arrow.webp"
            className="tb03-programs5-i1"
          />
        </div>
        <div className="tb03-programs5-r2">
          <TBProgramItem
            progNo="01"
            progTitle="LET ME SHOW YOU WHAT I SAW"
            progImgSrc="/touchbase/programs/p-01.webp"
            rootClassName="tb-program-item-root-class-name54"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs5-r3">
          <TBProgramItem
            progNo="02"
            progTitle="TOUCH_TIME (T_T)"
            progImgSrc="/touchbase/programs/p-02.webp"
            rootClassName="tb-program-item-root-class-name55"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs5-r4">
          <TBProgramItem
            progNo="03"
            progTitle="PERFOR.ML"
            progImgSrc="/touchbase/programs/p-03.webp"
            rootClassName="tb-program-item-root-class-name56"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs5-r5">
          <TBProgramItem
            progNo="04"
            progTitle="RANDOM ACCESS MEMORY"
            rootClassName="tb-program-item-root-class-name57"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs5-r6">
          <TBProgramItem
            progNo="05"
            progTitle="HOW TO GO ON"
            rootClassName="tb-program-item-root-class-name58"
          ></TBProgramItem>
        </div>
      </div>
      <div className="tb03-programs5-pop-container">
        <div className="tb03-programs5-c1">
          <Link to="/tb03-programs" className="tb03-programs5-link TB-F-L">
            X
          </Link>
          <span className="tb03-programs5-text TB-OCR-M">ABOUT</span>
          <span className="tb03-programs5-text01 TB-F-S">
            <span>
              What happens after a beginning? After finding our ways to each
              other, how do we continue walking together? Even as we celebrate
              newness, beginnings, and innovation, sometimes the most difficult
              thing to do is simply to go on.
            </span>
            <br></br>
            <br></br>
            <span>
              How To Go On is an intimate conversation with our friends Bussy
              Temple, School of Altruism, and The Slow Press, independent
              collectives whose work invites us to dream beyond the here and
              now. We’ll share about how each of our collectives persist. We’ll
              ask and share responses to questions of survival, sustainability,
              and how to dig our heels in once we’ve arrived.
            </span>
            <br></br>
            <br></br>
            <span>Come listen. Come conspire.</span>
            <br></br>
            <span>
              Come scheme and strategise about how we can stubbornly endure.
            </span>
          </span>
          <img
            alt="image"
            src="/touchbase/programs/p-05.webp"
            className="tb03-programs5-image"
          />
        </div>
        <div className="tb03-programs5-c2">
          <Link to="/tb03-programs" className="tb03-programs5-navlink TB-F-L">
            X
          </Link>
          <span className="tb03-programs5-text11 TB-OCR-S">
            <span>21 MAY 2024</span>
            <br></br>
            <span>7PM–9PM</span>
            <br></br>
            <span>POTATO PRODUCTIONS</span>
            <br></br>
            <span>284 RIVER VALLEY ROAD</span>
            <br></br>
            <span>SINGAPORE 238325</span>
          </span>
          <span className="tb03-programs5-text21 TB-OCR-XL">HOW TO GO ON</span>
          <a
            href="https://www.eventbrite.com/e/how-to-go-on-tickets-846875345957?aff=oddtdtcreator"
            target="_blank"
            rel="noreferrer noopener"
            className="tb03-programs5-link1 TB-OCR-S"
          >
            GET TICKETS
          </a>
        </div>
      </div>
    </div>
  );
};

export default TB03Programs5;
