import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './kinetic-birds.css'

const KineticBirds = (props) => {
  return (
    <div className="kinetic-birds-container">
      <Helmet>
        <title>Kinetic-Birds - Feelers 2024</title>
        <meta property="og:title" content="Kinetic-Birds - Feelers 2024" />
      </Helmet>
      <div className="kinetic-birds-bg-container">
        <img src="/feelers/bgcircle.png" className="kinetic-birds-image" />
        <img src="/feelers/bgcircle.png" className="kinetic-birds-image01" />
        <img src="/feelers/bgcircle.png" className="kinetic-birds-image02" />
      </div>
      <div className="kinetic-birds-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="kinetic-birds-body-container">
        <div className="kinetic-birds-left-container">
          <h1 className="kinetic-birds-title Title-XXL">Kinetic Birds</h1>
          <div className="kinetic-birds-img-container">
            <img
              src="/kineticbirds/kb-1.webp"
              className="kinetic-birds-image03"
            />
            <img
              src="/kineticbirds/kb-2.webp"
              className="kinetic-birds-image04"
            />
            <img
              src="/kineticbirds/kb-3.webp"
              className="kinetic-birds-image05"
            />
            <div className="kinetic-birds-col2">
              <img
                src="/kineticbirds/kb-4.webp"
                className="kinetic-birds-image06"
              />
              <img
                src="/kineticbirds/kb-5.webp"
                className="kinetic-birds-image07"
              />
            </div>
            <img
              src="/kineticbirds/kb-6.webp"
              className="kinetic-birds-image08"
            />
            <div className="kinetic-birds-col21">
              <img
                src="/kineticbirds/kb-7.webp"
                className="kinetic-birds-image09"
              />
              <img
                src="/kineticbirds/kb-8.webp"
                className="kinetic-birds-image10"
              />
            </div>
          </div>
        </div>
        <div className="kinetic-birds-right-container">
          <div className="kinetic-birds-header-container">
            <h1 className="kinetic-birds-text Body-L">
              How can we use simple and accessible tech tools to dream of
              creative projects?
            </h1>
          </div>
          <div className="kinetic-birds-body-container1">
            <div className="kinetic-birds-row1-container">
              <div className="kinetic-birds-left-container1"></div>
              <span className="kinetic-birds-text01 Body-M">
                <span>
                  Kinetic Birds is a beginner-friendly art and tech workshop
                  which imparts introductory and practical knowledge about
                  Arduino, a controller that helps create interactive electronic
                  objects using code.
                </span>
                <br></br>
                <br></br>
                <span>
                  Participants will take a hands-on approach to working with
                  Arduino microcontrollers, motors, and other electronic
                  components to play with movement and bring ideas to life.
                </span>
                <br></br>
                <br></br>
                <span>
                  Through the 4-hour workshop, participants will make new
                  friends and create a simple Kinetic Birds project that they
                  can bring home.
                </span>
                <br></br>
              </span>
            </div>
            <div className="kinetic-birds-row2-container">
              <span className="kinetic-birds-text10 Body-S">
                <span className="Body-S">
                  This workshop is intended for anyone involved in creative
                  fields (artists, designers, performers, makers, students,
                  etc.) interested in expanding their creative practice or
                  individuals looking to explore the possibilities of creative
                  coding.
                </span>
                <br className="Body-M"></br>
                <br className="Body-S"></br>
                <span className="Body-S">
                  Everyone has their own unique entry point. No prior experience
                  is necessary.
                </span>
              </span>
              <div className="kinetic-birds-col2-container">
                <span className="kinetic-birds-text15 Body-M">
                  <span>
                    Our workshop is carefully designed to be simple and
                    accessible, where materials are engaging and easy to
                    reference.
                  </span>
                  <br className="Body-M"></br>
                  <br className="Body-M"></br>
                  <span>
                    The Project Kit consists of specially selected electronics
                    required to complete the Kinetic Birds activity. The kit can
                    also be used to further develop a variety of personal
                    projects at home without the need to purchase many new parts
                    or upgrades.
                  </span>
                  <br className="Body-M"></br>
                  <br className="Body-M"></br>
                  <span>
                    The Workshop Pack contains all content covered during the
                    workshop including slides, media, and sample code. All
                    content is consolidated onto a single page for easy
                    reference in the future without needing to search through
                    multiple different files and folders.
                  </span>
                </span>
                <a
                  href="https://kinopio.club/-kinetic-birds-gExLowY5NKtAuUqzAQu7G"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="kinetic-birds-link Body-M"
                >
                  Find out more about the workshop here.
                </a>
              </div>
            </div>
            <div className="kinetic-birds-row3-container">
              <span className="kinetic-birds-text23 Body-M">
                <span>Giuseppe –</span>
                <br></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span className="kinetic-birds-text27 Body-M">
                <span>
                  This workshop was really fun and engaging, with very nice
                  introductions to the basics of both hardware and software and
                  a friendly coding approach. It got hands-on very quickly and
                  it ran pretty smoothly. Ashley and Kia Yee were very kind and
                  patient, spending one-on-one time with every participant to
                  make sure they could troubleshoot their code and other issues.
                  Definitely recommended.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </div>
            <div className="kinetic-birds-row4-container">
              <span className="kinetic-birds-text30 Body-M">
                <span>Keith –</span>
                <br></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span className="kinetic-birds-text34 Body-M">
                Really great overview on simple input/output concepts and tying
                them together with code. I loved having a concrete simple art
                project that could be displayed by the end of the session, and
                it really helped to spark interesting ways to use the things
                that we learned.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="kinetic-birds-row5-container">
              <span className="kinetic-birds-text35 Body-M">
                Jolin –
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="kinetic-birds-text36 Body-M">
                <span>
                  Very great! Super beginner friendly, but not too
                  oversimplified that I feel I didn&apos;t learn anything or
                  can&apos;t adapt what I learnt into my own projects~
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="Body-M">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </div>
            <div className="kinetic-birds-row6-container">
              <div className="kinetic-birds-left-container2"></div>
              <div className="kinetic-birds-right-container1">
                <a
                  href="https://kinopio.club/-feelers-pigeon-hole-8VBjWfi2YCjIwdspt3Ilf"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="kinetic-birds-link1 Body-M"
                >
                  Adopt a pigeon to register for our workshop!
                </a>
                <span className="kinetic-birds-text39">
                  Sorry, pigeon adoptions are currently closed.
                </span>
                <div className="kinetic-birds-text40">
                  <a
                    href="mailto:team@feelers-feelers.com?subject=Enquiry About Kinetic Birds"
                    className="kinetic-birds-link2 Body-M"
                  >
                    Email us
                  </a>
                  <span className="kinetic-birds-text41"> / </span>
                  <a
                    href="https://discord.gg/ppUr4Tnebj"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="kinetic-birds-link3 Body-M"
                  >
                    join our Discord
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </a>
                  <span className="kinetic-birds-text42"> / </span>
                  <a
                    href="https://www.instagram.com/feelers_feelers/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="kinetic-birds-link4 Body-M"
                  >
                    follow our Instagram 
                  </a>
                  <span className="kinetic-birds-text43 Body-M">
                    for updates.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="kinetic-birds-footer-container">
        <Footer rootClassName="footer-root-class-name"></Footer>
      </div>
    </div>
  )
}

export default KineticBirds
