import React from 'react'

import PropTypes from 'prop-types'

import './plot.css'

const Plot = (props) => {
  return (
    <div className="plot-plot">
      <svg viewBox="0 0 877.7142857142857 1024" className="plot-icon">
        <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
      </svg>
      <span className="plot-text Alt1-XS">{props.text}</span>
    </div>
  )
}

Plot.defaultProps = {
  text: 'Another World is Possible',
}

Plot.propTypes = {
  text: PropTypes.string,
}

export default Plot
