import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Profile from '../components/profile'
import Footer from '../components/footer'
import './another-world-is-possible.css'

const AnotherWorldIsPossible = (props) => {
  return (
    <div className="another-world-is-possible-container">
      <Helmet>
        <title>Another-World-is-Possible - Feelers 2024</title>
        <meta
          property="og:title"
          content="Another-World-is-Possible - Feelers 2024"
        />
      </Helmet>
      <div className="another-world-is-possible-bg-container">
        <img
          src="/feelers/bgcircle.png"
          className="another-world-is-possible-image"
        />
        <img
          src="/feelers/bgcircle.png"
          className="another-world-is-possible-image1"
        />
        <img
          src="/feelers/bgcircle.png"
          className="another-world-is-possible-image2"
        />
      </div>
      <div className="another-world-is-possible-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="another-world-is-possible-body-container">
        <div className="another-world-is-possible-left-container">
          <h1 className="another-world-is-possible-title Title-XXL">
            Another World is Possible
          </h1>
          <div className="another-world-is-possible-img-container">
            <img
              src="/anotherworld/anotherworld-5.webp"
              className="another-world-is-possible-image3"
            />
            <div className="another-world-is-possible-col2">
              <img
                src="/anotherworld/anotherworld-2.webp"
                className="another-world-is-possible-image4"
              />
              <img
                src="/anotherworld/anotherworld-4.webp"
                className="another-world-is-possible-image5"
              />
            </div>
            <img
              src="/anotherworld/anotherworld-1.webp"
              className="another-world-is-possible-image6"
            />
          </div>
        </div>
        <div className="another-world-is-possible-right-container">
          <div className="another-world-is-possible-header-container">
            <h1 className="another-world-is-possible-text Body-L">
              What are your true intentions in doing this work?
            </h1>
          </div>
          <div className="another-world-is-possible-body-container1">
            <div className="another-world-is-possible-row1-container">
              <div className="another-world-is-possible-left-container1">
                <span className="another-world-is-possible-text01 Body-S">
                  <span>library@harbourfront</span>
                  <br></br>
                  <span>(Programme Zone 1)</span>
                  <br className="Body-S"></br>
                  <span className="Body-S">3 September 2022</span>
                  <br className="Body-S"></br>
                  <span className="Body-S">4pm – 5.30pm</span>
                  <br className="Body-S"></br>
                </span>
                <a
                  href="https://go.gov.sg/fowee2022"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="another-world-is-possible-link Body-S"
                >
                  This programme is part of NLB’s Future of Work Expert Edition.
                </a>
              </div>
              <span className="another-world-is-possible-text10 Body-M">
                <span>
                  Another World is Possible is a panel discussion about the role
                  of design and technology in building a more ethical and
                  equitable world.
                </span>
                <br></br>
                <br></br>
                <span>
                  Bringing together Saad Chinoy (SpudnikLab), Claudia Poh
                  (Werable) and Wendy Wong (55 Minutes), Another World is
                  Possible responds to the pressing need for designs and
                  technologies which value and empower the autonomy and needs of
                  their users.
                </span>
                <br></br>
                <br></br>
                <span>
                  In the process, the panel will touch upon the deepening
                  digital divide between the super-rich and underprivileged, the
                  ongoing shift from Web 2.0 to Web 3.0, as well as various
                  considerations in achieving empathetic and user-centred
                  design.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br className="Body-M"></br>
              </span>
            </div>
            <div className="another-world-is-possible-row2-container">
              <div className="another-world-is-possible-col1-container">
                <Profile
                  profileImg="/anotherworld/saad.webp"
                  profileTitle="Speaker"
                  profileName="Saad Chinoy"
                  rootClassName="profile-root-class-name"
                ></Profile>
              </div>
              <div className="another-world-is-possible-col2-container">
                <span className="another-world-is-possible-text19 Body-M">
                  <span>
                    Saad is a professional geek with a passion for technology
                    and OpenSource methodology. Co founder of Singapore based
                    Frugal Innovation startup, SpudnikLab, a PotatoProductions
                    company that works to address the #digitalDivide through
                    digital skills education and effective use of low-cost
                    technologies. His Storytellers&apos; Kitchen and
                    EdibleMakerspace community initiatives bring together
                    writers, illustrators, researchers, publishers, citizen
                    scientists, and readers to demystify the complexity of
                    smart-phone interactivity AR / VR / stop-motion animation
                    through learning by doing.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    In the non-commercial context, Saad initiated SalvageGarden
                    the assistive technology makerspace that engages a community
                    of makers, engineers, care givers, persons with
                    disabilities, and care professionals towards the research
                    and development of Assistive Tech devices and low-cost
                    solutions.
                  </span>
                </span>
              </div>
            </div>
            <div className="another-world-is-possible-row3-container">
              <div className="another-world-is-possible-col1-container1">
                <Profile
                  profileImg="/anotherworld/claudia.webp"
                  profileTitle="Speaker"
                  profileName="Claudia Poh"
                  rootClassName="profile-root-class-name"
                ></Profile>
              </div>
              <div className="another-world-is-possible-col2-container1">
                <span className="another-world-is-possible-text24 Body-M">
                  <span>
                    Claudia is an entrepreneur and finds great joy in innovating
                    useful solutions that can be experienced and worn. She is a
                    designer who&apos;s passionate about helping the community
                    grow their confidence so that they thrive and become the
                    best version of themselves.
                  </span>
                  <br className="Body-M"></br>
                  <br className="Body-M"></br>
                  <span>
                    Claudia’s journey in the field of adaptive fashion began in
                    2017, where she got the opportunity to co-create with
                    friends and also the disability communities in New York and
                    Singapore. From hands-free dressing systems to
                    wheelchair-accessible clothing, She has collaborated with
                    like-minded problem solvers, makers, product designers, and
                    occupational therapists.
                  </span>
                  <br className="Body-M"></br>
                  <br className="Body-M"></br>
                  <span>
                    Claudia founded Werable, a business that seeks innovative
                    new ways to construct clothes so that regardless of your
                    mobility, getting dressed can continue to be an exciting and
                    empowering experience.
                  </span>
                </span>
              </div>
            </div>
            <div className="another-world-is-possible-row4-container">
              <div className="another-world-is-possible-col1-container2">
                <Profile
                  profileImg="/anotherworld/wendy.webp"
                  profileTitle="Speaker"
                  profileName="Wendy Wong"
                  rootClassName="profile-root-class-name"
                ></Profile>
              </div>
              <div className="another-world-is-possible-col2-container2">
                <span className="another-world-is-possible-text32 Body-M">
                  <span>
                    Wendy is a UX designer and a certified usability analyst,
                    who believes empathy plays an important role both in design
                    and in creating positive workplaces for teams to thrive.
                  </span>
                  <br className="Body-M"></br>
                  <br className="Body-M"></br>
                  <span>
                    With more than 15 years of experience leading companies in
                    the publishing, print, and digital design industry, she is
                    now the head of 55 Minutes, the user-experience design
                    studio she co-founded. Her experience in client and project
                    management, as well as creative collaborations, enable her
                    to be the best bridge between technology, design and users.
                  </span>
                  <br className="Body-M"></br>
                  <br className="Body-M"></br>
                  <span>
                    She combines her wealth of experience with empathy, to solve
                    business and user problems by meeting human needs —
                    functional, emotional, social needs. With her M.A. from
                    Hyper Island, as well as from her continuous pursuit of
                    learning led by organizations like IDEO, Human Factors
                    International and INSEAD, Wendy works collaboratively with
                    her team of designers from various disciplines to deliver
                    delightful solutions through the human-centered design
                    approach.
                  </span>
                </span>
              </div>
            </div>
            <div className="another-world-is-possible-row5-container">
              <div className="another-world-is-possible-col1-container3">
                <Profile
                  profileImg="/profiles/kiayee.webp"
                  profileTitle="Moderator"
                  profileName="Ang Kia Yee"
                  rootClassName="profile-root-class-name"
                ></Profile>
              </div>
              <div className="another-world-is-possible-col2-container3">
                <span className="another-world-is-possible-text40 Body-M">
                  Ang Kia Yee is a transdisciplinary poet, artist and Label Lead
                  of Feelers, an artist-run art &amp; tech label which creates
                  art works and experiences centred on its interdisciplinary and
                  collaborative vision of art &amp; technology. Her work within
                  and without Feelers is driven toward the regeneration and
                  sustenance of alternative worlds and futures where life is
                  inhabitable and loving for all beings.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="another-world-is-possible-footer-container">
        <Footer rootClassName="footer-root-class-name"></Footer>
      </div>
    </div>
  )
}

export default AnotherWorldIsPossible
