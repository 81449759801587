import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import TBNavbar from "../components/tb-navbar";
import TBProgramItem from "../components/tb-program-item";
import "./tb03-programs1.css";

const TB03Programs1 = (props) => {
  return (
    <div className="tb03-programs1-container">
      <Helmet>
        <title>TB03-Programs1 - Feelers 2024</title>
        <meta property="og:title" content="TB03-Programs1 - Feelers 2024" />
      </Helmet>
      <div className="tb03-programs1-bg-container"></div>
      <div className="tb03-programs1-navbar-container">
        <TBNavbar rootClassName="tb-navbar-root-class-name5"></TBNavbar>
      </div>
      <div className="tb03-programs1-body-container">
        <div className="tb03-programs1-r1">
          <span className="tb03-programs1-t1 TB-OCR-XXL">Programs</span>
          <img
            alt="image"
            src="/touchbase/b-arrow.webp"
            className="tb03-programs1-i1"
          />
        </div>
        <div className="tb03-programs1-r2">
          <TBProgramItem
            progNo="01"
            progTitle="LET ME SHOW YOU WHAT I SAW"
            progImgSrc="/touchbase/programs/p-01.webp"
            rootClassName="tb-program-item-root-class-name20"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs1-r3">
          <TBProgramItem
            progNo="02"
            progTitle="TOUCH_TIME (T_T)"
            progImgSrc="/touchbase/programs/p-02.webp"
            rootClassName="tb-program-item-root-class-name21"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs1-r4">
          <TBProgramItem
            progNo="03"
            progTitle="PERFOR.ML"
            progImgSrc="/touchbase/programs/p-03.webp"
            rootClassName="tb-program-item-root-class-name22"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs1-r5">
          <TBProgramItem
            progNo="04"
            progTitle="RANDOM ACCESS MEMORY"
            rootClassName="tb-program-item-root-class-name23"
          ></TBProgramItem>
        </div>
        <div className="tb03-programs1-r6">
          <TBProgramItem
            progNo="05"
            progTitle="HOW TO GO ON"
            rootClassName="tb-program-item-root-class-name24"
          ></TBProgramItem>
        </div>
      </div>
      <div className="tb03-programs1-pop-container">
        <div className="tb03-programs1-c1">
          <Link to="/tb03-programs" className="tb03-programs1-navlink TB-F-L">
            X
          </Link>
          <span className="tb03-programs1-text TB-OCR-M">ABOUT</span>
          <span className="tb03-programs1-text01 TB-F-S">
            <span>
              Somewhere in Kent Ridge, a tree creeps toward a precious serving
              of open sky. Its branches gently swoop into a footpath below, as
              if reaching out to the people passing by. With Open Offering 1,
              project group About to Ask re-spatialises the relationship between
              this tree and the passersby who engage with it.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span>
              Taking the exhibition’s interest in dialogue and drawing as a
              point of departure, Let me show you what I saw explores the
              technologies of representation in art &amp; culture, including
              writing, drawing, and photography. We’ll think through these
              artistic mediums as technologies which transmit experiences
              between people, attempting to generate a shared space of knowing,
              feeling, and dreaming. The sharing includes a series of hands-on
              exercises, where participants attempt experimental ways of
              representing trees.
            </span>
            <br></br>
          </span>
          <img
            alt="image"
            src="/touchbase/programs/p-01.webp"
            className="tb03-programs1-image"
          />
        </div>
        <div className="tb03-programs1-c2">
          <Link to="/tb03-programs" className="tb03-programs1-navlink1 TB-F-L">
            X
          </Link>
          <span className="tb03-programs1-text07 TB-OCR-S">
            <span>23 MARCH 2024</span>
            <br></br>
            <span>3PM–5PM</span>
            <br></br>
            <span>[HEARTH] ART OUTREACH</span>
            <br></br>
            <span>5 LOCK ROAD, #01-06</span>
            <br></br>
            <span>SINGAPORE 108933</span>
            <br></br>
          </span>
          <span className="tb03-programs1-text18 TB-OCR-XL">
            LET ME SHOW YOU WHAT I SAW
          </span>
          <a
            href="https://www.eventbrite.com/e/let-me-show-you-what-i-saw-tickets-846773451187"
            target="_blank"
            rel="noreferrer noopener"
            className="tb03-programs1-link"
          >
            GET TICKETS
          </a>
        </div>
      </div>
    </div>
  );
};

export default TB03Programs1;
