import React from 'react'

import { Helmet } from 'react-helmet'

import TBNavbar from '../components/tb-navbar'
import Footer from '../components/footer'
import './tb02-intro.css'

const TB02Intro = (props) => {
  return (
    <div className="tb02-intro-container">
      <Helmet>
        <title>TB02-Intro - Feelers 2024</title>
        <meta property="og:title" content="TB02-Intro - Feelers 2024" />
      </Helmet>
      <div className="tb02-intro-bg-container"></div>
      <div className="tb02-intro-navbar-container">
        <TBNavbar rootClassName="tb-navbar-root-class-name"></TBNavbar>
      </div>
      <div className="tb02-intro-body-container">
        <span className="tb02-intro-t1 TB-OCR-XXL">Introduction</span>
        <span className="tb02-intro-t2 TB-OCR-L">Let&apos;s Touch Base</span>
        <span className="tb02-intro-t3 TB-F-M">
          <span className="TB-F-M">
            In 1901, a shipwreck was found off the coast of Greek island
            Antikythera. 
          </span>
          <span className="TB-F-M">
            Amidst larger objects such as pottery and statues was a corroded
            lump of bronze and wood. Despite a gear being identified within it
            and suggestions that the lump was in fact an astronomical
            calculator, it was not until 1951 that interest in it was revived.
            In 1971, X-ray and gamma-ray images of the 82 fragments were made.
            In 2008, its faint inscriptions were imaged through computer x-ray
            tomography and high-resolution scanning.
          </span>
          <br className="TB-F-M"></br>
          <br className="TB-F-M"></br>
          <span className="TB-F-M">
            The object is now the oldest known example of an analogue computer.
            Called the Antikythera mechanism, the hand-powered orrery could
            predict astronomical positions and eclipses. This discovery upended
            what scholars understood about the history of technology. Somehow,
            Hellenistic scientists had designed and built a tool considered too
            complex to have been made in their time—one which could track the
            movements of the Sun, Moon, and possibly the other five classical
            planets: Mars, Mercury, Venus, Jupiter, and Saturn.
          </span>
          <br className="TB-F-M"></br>
        </span>
        <span className="tb02-intro-t4 TB-F-M">
          What else do we think we know for certain about the world?
        </span>
        <span className="tb02-intro-t5 TB-F-M">
          <span className="TB-F-M">
            We rest upon the unstable grounds of our conclusions. We still build
            machines that might tell us what’s coming next. Our systems,
            formerly built for the accumulation of knowledge, are now rewired
            for the acquisition of data. We continue to extinguish mystery and
            chance.
          </span>
          <br></br>
          <br></br>
          <span>
            To be open to chance is to be open to error. To be open to chance is
            to be open to possibility—of joy, sorrow, resonance, dissonance,
            boredom, anxiety, surprise. In the face of polarising politics, do
            we still know how to speak to one another across difference and
            deviation? While we may defend our principles, do we understand how
            to stay with mystery? Even mystery that infuriates us, or causes
            deep anxiety to well up?
          </span>
          <br></br>
          <br></br>
          <span>
            The work we do at Feelers is mysterious work. While our programmes
            are designed to demystify technology and deepen collective thinking
            around complex futures, we insist on keeping our field open, and
            allowing mystery to seep in. This is true not only in our work, but
            also in our relationships as friends and co-workers. While art and
            technology form key fields of research, they continually lead us
            back to one another. It is with one another that our real work takes
            place.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
        </span>
        <span className="tb02-intro-t6 TB-F-M">
          This year, we open the field up to you with Touch Base —
        </span>
        <span className="tb02-intro-t7 TB-F-M">
          <span className="TB-F-M">
            A series of interdisciplinary programmes that flow along circuits
            and fault lines between art and technology. As our inaugural Season,
            Touch Base contends with our most fundamental assumptions about art
            and technology. We begin by going back to basics, going back to the
            body. We turn toward the mystery of each other.
          </span>
          <br></br>
          <br></br>
          <span>
            Programmes will run along a range of formats to make room for
            learning, conversations, and skill-building with fellow artists,
            technologists, makers, explorers, stewards, and builders of the
            world. They include perfor.ml, Feelers’ first in-house performance
            project; Let me show you what I saw, a workshop about technologies
            of representation (writing, drawing, photography) as part of Open
            Offering by About to Ask; and How To Go On, a closing panel on how
            independent artist groups endure.
          </span>
          <br></br>
          <br></br>
          <span>
            Playing counterpoint to more formal programmes are Touch_Time (T_T)
            sessions, where you can play Cards Against Technology with us, and
            explore our hopes and fears about the future together.
          </span>
          <br></br>
        </span>
        <span className="tb02-intro-t8 TB-F-M">
          So let us go towards mystery—towards each other. And perhaps, at the
          end of it all, we’ll have learnt new things about ourselves, one
          another, and the world. We might feel more able to persist creatively,
          strategically, and lovingly in the face of a volatile world. We might
          even have changed our minds about what we thought to be true and
          possible.
        </span>
      </div>
      <div className="tb02-intro-footer-container">
        <Footer rootClassName="footer-root-class-name33"></Footer>
      </div>
    </div>
  )
}

export default TB02Intro
