import React from 'react'

import PropTypes from 'prop-types'

import './tb-credit.css'

const TBCredit = (props) => {
  return (
    <div className={`tb-credit-container ${props.rootClassName} `}>
      <span className="tb-credit-text TB-OCR-S">{props.creditName}</span>
      <img alt="image" src={props.creditImg} className="tb-credit-image" />
      <span className="tb-credit-text1 TB-F-S">{props.creditBio}</span>
    </div>
  )
}

TBCredit.defaultProps = {
  creditImg: 'https://play.teleporthq.io/static/svg/default-img.svg',
  creditBio: 'Bio',
  rootClassName: '',
  creditName: 'Name',
}

TBCredit.propTypes = {
  creditImg: PropTypes.string,
  creditBio: PropTypes.string,
  rootClassName: PropTypes.string,
  creditName: PropTypes.string,
}

export default TBCredit
