import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './you-tube.css'

const YouTube = (props) => {
  return (
    <div className="you-tube-container">
      <Helmet>
        <title>YouTube - Feelers 2024</title>
        <meta property="og:title" content="YouTube - Feelers 2024" />
      </Helmet>
      <div className="you-tube-bg-container">
        <img src="/feelers/bgcircle.png" className="you-tube-image" />
        <img src="/feelers/bgcircle.png" className="you-tube-image1" />
        <img src="/feelers/bgcircle.png" className="you-tube-image2" />
      </div>
      <div className="you-tube-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="you-tube-body-container">
        <div className="you-tube-left-container">
          <h1 className="you-tube-title Title-XXL">YouTube</h1>
        </div>
        <div className="you-tube-right-container">
          <h1 className="you-tube-text Body-L">
            We believe in making art &amp; technology more accessible while
            staying true to a spirit of play &amp; experimentation.
          </h1>
          <div className="you-tube-body-container1">
            <div className="you-tube-row1-container">
              <span className="you-tube-text1 Body-M">
                <span>Videos</span>
                <br></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <div className="you-tube-col2-container">
                <div className="you-tube-iframe-container">
                  <div className="you-tube-container1">
                    <div className="you-tube-container2">
                      <Script
                        html={`<iframe width="100%" height="100%" src="https://www.youtube.com/embed/rfhd9OGo2k0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`}
                      ></Script>
                    </div>
                  </div>
                </div>
                <div className="you-tube-iframe-container1">
                  <div className="you-tube-container3">
                    <div className="you-tube-container4">
                      <Script
                        html={`<iframe width="100%" height="100%" src="https://www.youtube.com/embed/_bU82zIoL34" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`}
                      ></Script>
                    </div>
                  </div>
                </div>
                <div className="you-tube-iframe-container2">
                  <div className="you-tube-container5">
                    <div className="you-tube-container6">
                      <Script
                        html={`<iframe width="100%" height="100%" src="https://www.youtube.com/embed/yM80fMxqU3A" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`}
                      ></Script>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="you-tube-footer-container">
        <Footer rootClassName="footer-root-class-name"></Footer>
      </div>
    </div>
  )
}

export default YouTube
