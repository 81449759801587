import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './generative-dreaming.css'

const GenerativeDreaming = (props) => {
  return (
    <div className="generative-dreaming-container">
      <Helmet>
        <title>Generative-Dreaming - Feelers 2024</title>
        <meta
          property="og:title"
          content="Generative-Dreaming - Feelers 2024"
        />
      </Helmet>
      <div className="generative-dreaming-bg-container">
        <img
          src="/feelers/bgcircle.png"
          className="generative-dreaming-image"
        />
        <img
          src="/feelers/bgcircle.png"
          className="generative-dreaming-image1"
        />
        <img
          src="/feelers/bgcircle.png"
          className="generative-dreaming-image2"
        />
      </div>
      <div className="generative-dreaming-navbar-container">
        <Navbar navImgSrc="/feelers/feelerslogo.png"></Navbar>
      </div>
      <div className="generative-dreaming-body-container">
        <div className="generative-dreaming-left-container">
          <h1 className="generative-dreaming-title Title-XXL">
            Generative Dreaming
          </h1>
          <div className="generative-dreaming-img-container">
            <img src="/work/gd.webp" className="generative-dreaming-image3" />
            <img
              src="/generativedreaming/gd-2.webp"
              className="generative-dreaming-image4"
            />
            <img
              src="/generativedreaming/gd-3.webp"
              className="generative-dreaming-image5"
            />
          </div>
        </div>
        <div className="generative-dreaming-right-container">
          <div className="generative-dreaming-header-container">
            <h1 className="generative-dreaming-text Body-L">
              Other worlds together.
            </h1>
          </div>
          <div className="generative-dreaming-body-container1">
            <div className="generative-dreaming-row1-container">
              <div className="generative-dreaming-col1-container">
                <span className="generative-dreaming-text01 Body-M">
                  <span>Artists</span>
                  <br></br>
                </span>
                <span className="generative-dreaming-text04 Body-S">
                  <span>Xafiér Yap</span>
                  <br></br>
                  <span>Ang Kia Yee</span>
                  <br></br>
                  <span>Daniel Chong</span>
                  <br></br>
                  <span>Ashley Hi</span>
                  <br></br>
                  <span>Chantel Foo</span>
                  <br></br>
                  <span>Chok Si Xuan</span>
                  <br></br>
                </span>
                <span className="generative-dreaming-text17 Body-M">
                  <span>Music By</span>
                  <br></br>
                </span>
                <span className="generative-dreaming-text20 Body-S">
                  <span>madam data</span>
                  <br></br>
                </span>
                <span className="generative-dreaming-text23 Body-M">
                  <span>Edited By</span>
                  <br></br>
                </span>
                <span className="generative-dreaming-text26 Body-S">
                  <span>Ang Kia Yee</span>
                  <br></br>
                </span>
              </div>
              <span className="generative-dreaming-text29 Body-M">
                <span>
                  Generative Dreaming reaches toward other worlds and futures
                  through conversations between young artists who are learning
                  how to make, how to persist, and what they desire.
                </span>
                <br className="Body-M"></br>
                <br className="Body-M"></br>
                <span>
                  Season 1 gathers practitioners who work across performance,
                  installation, sculpture, and digital media to explore
                  intersections between their practices.
                </span>
                <br className="Body-M"></br>
                <br className="Body-M"></br>
                <span>
                  In each episode, two artists chat about what, why and how they
                  make what they make, as well as their visions for the future
                  and the wider world. Their conversations reveal how we persist
                  through care, community and creative strategy – not just as
                  artists, but also as people.
                </span>
                <br className="Body-M"></br>
                <br className="Body-M"></br>
                <span>
                  Taking a structured but meandering approach, the artists of
                  each episode roam across various themes — such as audience,
                  body politics, and glitching. They reach toward one another to
                  see what emerges, what is possible where they touch.
                </span>
                <br className="Body-M"></br>
                <span className="Body-M">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </div>
            <div className="generative-dreaming-row3-container">
              <span className="generative-dreaming-text42 Body-M">
                <span>Episodes</span>
                <br></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <div className="generative-dreaming-col2-container">
                <div className="generative-dreaming-container01">
                  <div className="generative-dreaming-container02">
                    <Script
                      html={`<iframe style="border-radius:12px" src="https://open.spotify.com/embed/episode/3RGjzHkyYYfxKaL8H2Rquw?utm_source=generator&t=0" width="100%" height="90px" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture" loading="lazy"></iframe>`}
                    ></Script>
                  </div>
                </div>
                <div className="generative-dreaming-container03">
                  <div className="generative-dreaming-container04">
                    <Script
                      html={`<iframe style="border-radius:12px" src="https://open.spotify.com/embed/episode/0Iw5YovmInj35WyGShPiDh?utm_source=generator&t=0" width="100%" height="90px" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture" loading="lazy"></iframe>`}
                    ></Script>
                  </div>
                </div>
                <div className="generative-dreaming-container05">
                  <div className="generative-dreaming-container06">
                    <Script
                      html={`<iframe style="border-radius:12px" src="https://open.spotify.com/embed/episode/4NvmAr6K3zV7p6QMItwhUc?utm_source=generator&t=0" width="100%" height="90px" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture" loading="lazy"></iframe>`}
                    ></Script>
                  </div>
                </div>
                <div className="generative-dreaming-container07">
                  <div className="generative-dreaming-container08">
                    <Script
                      html={`<iframe style="border-radius:12px" src="https://open.spotify.com/embed/episode/4n3bTjb53ljiOBCd4qs1Gc?utm_source=generator&t=0" width="100%" height="90px" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture" loading="lazy"></iframe>`}
                    ></Script>
                  </div>
                </div>
                <div className="generative-dreaming-container09">
                  <div className="generative-dreaming-container10">
                    <Script
                      html={`<iframe style="border-radius:12px" src="https://open.spotify.com/embed/episode/2wHbEyZSnPHbDyeHlVIf88?utm_source=generator&t=0" width="100%" height="90px" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture" loading="lazy"></iframe>`}
                    ></Script>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="generative-dreaming-footer-container">
        <Footer rootClassName="footer-root-class-name"></Footer>
      </div>
    </div>
  )
}

export default GenerativeDreaming
